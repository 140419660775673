<template>
  <div v-xe-pm.E.A>
    <div class="d-flex flex-row">
      <div class="col-3 p-l-0 p-r-0">
        <div class="d-flex flex-row pull-left align-items-center">
          <div class="h4 m-b-0">
            {{ $t("관제점 구성 관리") }} > {{ $t("관제점 계산식 관리") }}
            <!-- <button @click="runCalc">test</button>
						<button @click="replaceLogic">newtest</button> -->
          </div>
          <div class="pl-5 ml-3">
            <button
              class="btn btn-white"
              @click="$refs.workflowGroupModal.show()"
              v-b-tooltip.hover
              :title="$t('관제점 계산식 목록')"
            >
              <i class="fa fa-cog"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin: 10px 0px 10px 0px" />

    <div class="row form-group">
      <div class="col-3">
        <panel
          :variant="panelVariant"
          noButton="true"
          :fixedHeight="780"
          bodyClass="p-0"
          headClass="hide bg-white"
        >
          <xe-workflow-tree
            :workflows="workflows"
            @select-node="onSelectNode"
          />
        </panel>
      </div>
      <div class="col-9">
        <div class="row">
          <!-- 상단 UPL 부분 -->
          <div class="col-12">
            <panel
              :variant="panelVariant"
              noButton="true"
              :fixedHeight="300"
              headClass="hide bg-white"
              bodyClass="p-b-0 timelineBody overflow-visible"
            >
              <xe-upl
                ref="xeUPL"
                :currentWorkflow="currentWorkflow"
                :parentUpl="parentUpl"
                :currentUpl="currentUpl"
                :calcPointList="calcPointList"
                @insert-upl="onInsertUpl"
                @save-done="onWorkgroupSaveDone"
                readonly
                disabled
              />
            </panel>
          </div>
        </div>

        <!-- 하단 그리드 부분 -->
        <div class="row">
          <div class="col-12">
            <panel
              :variant="panelVariant"
              noButton="true"
              bodyClass="getOverFlow"
              :fixedHeight="460"
              headClass="hide"
              :style="{ overflow: 'auto' }"
            >
              <xe-live-table
                :currentWorkflow="currentWorkflow"
                :currentUpl="currentUpl"
                :liveReals="liveReals"
                :currentLiveDate="currentLiveDate"
                :ptMapData="ptMapData"
                :commonCodeList="commonCodeList"
                :pointList="pointList"
                @select-row="onTimelineClick"
                :calcPointData="calcPointData"
                :calcPointList="calcPointList"
                :selectViewGroup="
                  $refs.xeUPL ? $refs.xeUPL.selectViewGroup : 'workflow'
                "
              />
            </panel>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="workflowGroupModal"
      :title="$t('워크플로우 목록')"
      :hide-footer="true"
    >
      <xe-workgroup-detail
        :workgroupName="curWorkgroupName"
        @save-done="onWorkgroupSaveDone"
      />
    </b-modal>
  </div>
</template>

<style scoped>
/* 신규추가 start*/
.panel {
  background-color: white !important;
  overflow: visible;
}

:not(.panel) {
  height: 100%;
}
/* 신규추가 end*/

a.disabled {
  /* Make the disabled links grayish*/
  color: gray;
  /* And disable the pointer events */
  pointer-events: none;
}
.xe-borderless {
  border: 0px solid transparent;
  border-color: #fff;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import WorkflowMgr from "./WorkflowMgr.js";
import xeWorkflowTree from "./components/xeWorkflowTree.vue";
import xeUpl from "./components/xeUpl.vue";
import xeLiveTable from "./components/xeLiveTable.vue";
import xeWorkgroupDetail from "./components/xeWorkgroupDetail.vue";
import xelib from "xelib";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {
    xeWorkflowTree,
    xeUpl,
    xeLiveTable,
    xeWorkgroupDetail,
  },
  data() {
    return {
      trigger: {
        startDt: new Date().format("yyyy-MM-dd 00:00"),
        //ticks : 1000,
        //intervalMin : 1,
        //enable: true,
      },
      //workgroups: [],
      workflowObjs: [],
      workflowMgr: null,

      currentWorkflow: {},
      parentUpl: {},
      currentUpl: {},

      // calcuateMode: "Manual",

      // liveTable 관련
      currentLiveDate: null,
      currentLiveReal: {},
      timeLines: [],
      watchPt: "",
      calcObject: {
        job: null,
        ptMap: null,
        trMap: null,
        _props: null,
        _zeros: null,
      },
      calcPointData: [],
      calcPointList: [],
      ptMapData: [],
      pointList: [],
      commonCodeList: {},
    };
  },
  computed: {
    workflows() {
      if (this.workflowMgr && this.workflowMgr.workgroup) {
        return this.workflowMgr.workgroup.workflows;
      }
      return [];
    },
    curWorkgroupName() {
      if (this.workflowMgr && this.workflowMgr.workgroup) {
        return this.workflowMgr.workgroup.workgroupName;
      }
      return "";
    },
    // status(){
    //   return this.workflowMgr ? this.workflowMgr.status : "stop";
    // },
    sortedTimeLines() {
      return this.timeLines.unique().sort();
    },
    liveReals() {
      return JSON.parse(this.$store.getters.getLiveReal);
    },
  },
  watch: {
    "this.trigger.startDt": function () {
      this.currentLiveDate = null;
    },
    // "this.$refs.xeUPL.selectWorkflowGroup": function(value){
    //   console.log(value);
    // }
  },
  mounted() {
    // let times = new Date("Mon, 31 Jul 2023 05:51:34 GMT").getTime();
    // let encod = `${times}`
    // console.log(times);
    console.log(global.xe);
    this.getPointList();
    this.getCommonCode();
    this.getUnitSmallCodeList();
    this.getZoneListHierarchi();
    this.bindData();
    console.log(this.$refs);
  },
  methods: {
    bindData() {
      //this.workgroups.clear();
      this.workflowObjs.clear();
      this.workflowMgr = null;
      this.currentWorkflow = {};
      (this.parentUpl = {}), (this.currentUpl = {});
      this.timeLines.clear();

      // 작업그룹 목록 조회
      backEndApi.workflow.searchWorkgroups().then(({ data }) => {
        console.log(data);

        if (this.$err(data)) return;

        let workgroups = data;

        // 워크플로우 목록 조회
        backEndApi.workflow.searchAllJobs().then(({ data }) => {
          console.log(data);

          if (this.$err(data)) return;

          this.workflowObjs.range(data);

          // 계산된 값
          this.workflowMgr = new WorkflowMgr(this.watchPt);
          this.workflowMgr.on_change = this.onChangeTotalReal;

          this.onSelectWorkgroup(workgroups.first());
        });
      });
    },
    onSelectWorkgroup(workgroup) {
      if (this.isEmpty(workgroup)) return;

      // 선택 그룹 할당
      this.workflowMgr.workgroup = workgroup;

      workgroup.workflows = workgroup.workflows
        .map((name) => {
          return this.workflowObjs.find((workflow) => workflow.name === name);
        })
        .filter((v) => v);

      this.currentWorkflow = workgroup.workflows.first();
    },
    onSelectNode(node) {
      if (node.data.workflow && node.data.workflow.uplType == "Start") {
        this.currentWorkflow = node.data;
      } else if (node.data.uplType == "Group") {
        this.currentWorkflow = node.parent.data;
      } else {
        this.currentWorkflow = node.parent.parent.data;
      }

      this.parentUpl = node.parent ? node.parent.data : {};
      this.currentUpl = node.data;

      this.getPtMapData();

      this.replaceLogic();
    },
    async getCommonCode() {
      const result = await backEndApi.codes.getCommonCodeList();
      const data = result.data;
      this.publicCodeList = data.publicCode;
      const publicCodes = this.publicCodeList;

      // 에너지 공급유형 코드
      // this.energySplyTypeCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "energySplyTypeCode"));
      // console.log(this.energySplyTypeCodeList);
      this.energySplyTypeCodeList =
        await backEndApi.publicCode.getOprtnSmallCodeList("energySplyTypeCode");
      this.energySplyTypeCodeList = this.energySplyTypeCodeList.data;

      // 관제점 유형
      this.commonCodeList["isVirtual"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "isVirtual")
      );

      // 집계 유형
      this.commonCodeList["pyTypeCode"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "ptTypeCode")
      );

      // 관제점 용도
      this.commonCodeList["itemUseageCode"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "itemUseageCode")
      );

      // 에너지원
      this.commonCodeList["energySourceCode"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "energySourceCode")
      );

      // 물리 관제점 유형
      this.commonCodeList["phscPointUsage"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "phscPointUsage")
      );

      // 가상(논리) 관제점 유형
      this.commonCodeList["locPointUsage"] = this.commonSmallCodeList(
        publicCodes.filter((data) => data.largeCode == "locPointUsage")
      );
    },
    async getUnitSmallCodeList(unitLargeCode) {
      if (this.isEmpty(unitLargeCode)) return;

      try {
        const result = await backEndApi.unitCode.getUnitSmallCodeList(
          unitLargeCode
        );
        this.commonCodeList["unitSmallCode"] = result.data;
      } catch (err) {
        new AppErrorHandler(err).printErrorLog().errHandling();
      }
    },
    async getZoneListHierarchi() {
      try {
        const result = await backEndApi.zoneInfo.getZoneListHierarchi();
        this.commonCodeList["applyZoneIdx"] = result.data;
      } catch (err) {
        new AppErrorHandler(err).printErrorLog().errHandling();
      }
    },
    commonSmallCodeList(commonCode) {
      let targetData = [];

      commonCode.map((v) => {
        if (v.smallCodeList) {
          v.smallCodeList.map((t) => {
            targetData.push(t);
          });
        }
      });

      return targetData;
    },
    async getPointList() {
      this.pointList = await backEndApi.pointInfo.getPointListNew();
      this.pointList = this.pointList.data;
    },
    async getPtMapData() {
      for (let i = 0; i < this.currentUpl.children.length; i++) {
        const point = this.currentUpl.children[i];
        const pointIdx = this.pointList.find((v) => v.ptAddr === point.ptAddr);
        console.log(pointIdx);

        const result = await backEndApi.pointInfo.getPointDetailNew(
          pointIdx.ptIdx
        );

        this.ptMapData.push(result.data);
      }

      // backEndApi.pointInfo.getPointDetailNew()
    },
    onWorkgroupSaveDone() {
      this.$refs.workflowGroupModal.hide();

      this.bindData();
    },
    onChangeTotalReal(liveDate, liveReal) {
      this.liveReals[liveDate] = liveReal || {};
      this.liveReals = Object.assign({}, this.liveReals);

      // timeline에 결과를 등록하고, 마지막 결과를 선택해주면, 해당 시점의 데이터가 화면에 강조된다.
      this.onTimelineClick(liveDate);
    },
    // liveDate, isUiClick
    onTimelineClick(liveDate, isUiClick) {
      if (isUiClick) this.isLive = false;

      this.currentLiveDate = liveDate;
      this.currentLiveReal = this.liveReals[liveDate];

      this.trigger.startDt = liveDate.format("yyyy-MM-dd HH:mm");

      // timeline이 추가되면, scroll시킨다.
      if (isUiClick === false) {
        let tlBody = this.$el.querySelector(".timelineBody");
        let tlList = this.$el.querySelector(".timelineList");

        let total = this.timeLines.length;
        let idx = this.timeLines.indexOf(liveDate);

        let distance = (tlList.clientHeight / total) * idx;

        tlBody.scrollTop = distance;
      }
    },

    async replaceLogic() {
      this.calcPointData = [];
      this.calcPointList = [];

      if (this.currentUpl.uplType !== "Point") return;

      // if(this.currentUpl)

      // let logicSeperate = this.currentUpl.logic.replaceAll("+", "+\n");
      // logicSeperate = logicSeperate.replaceAll("-", "-\n");

      // console.log(logicSeperate);

      // let regex = new RegExp(/PZ\[.*\]/, "gi");

      // console.log(regex);
      console.log(this.currentUpl.logic);
      let pointMaps = this.currentUpl.logic.split("PZ");

      pointMaps = pointMaps.filter((v) => v.includes("[") && v.includes("]"));

      // console.log(pointMaps);
      // let pointMaps = this.currentUpl.logic.match(regex);
      let liveReal = JSON.parse(this.$store.getters.getLiveReal);

      if (
        this.calcPointList.findIndex((v) => v === this.currentUpl.ptAddr) === -1
      )
        this.calcPointList.push(this.currentUpl.ptAddr);

      // console.log(pointMaps);

      if (pointMaps !== null) {
        for (let i = 0; i < pointMaps.length; i++) {
          let ptAddr = pointMaps[i].split("[")[1].split("]")[0];

          if (this.calcPointList.findIndex((v) => v === ptAddr) === -1) {
            this.calcPointList.push(ptAddr);

            this.calcPointData.push({
              ptAddr: ptAddr,
              ptVal: this.isEmpty(liveReal[ptAddr]?.ptVal ?? "-"),
            });
          }
        }
      }

      if (
        this.calcPointData.findIndex(
          (v) => v.ptAddr === this.currentUpl.ptAddr
        ) === -1
      ) {
        this.calcPointData.unshift({
          ptAddr: this.currentUpl.ptAddr,
          ptVal: liveReal[this.currentUpl.ptAddr]
            ? liveReal[this.currentUpl.ptAddr].ptVal
            : null,
        });
      }
    },

    async runCalc() {
      let result = new xelib.PointBoxV2.Calculator(
        this.currentUpl.name,
        this.currentUpl.ptAddr
      );

      this.calcObject.job = {
        name: this.currentUpl.name,
        workflow: this.currentUpl,
      };

      this.calcObject.ptMap = JSON.parse(localStorage.liveAM);

      this.calcObject.trMap = JSON.parse(this.$store.getters.getLiveReal);

      this.isCalcData = await result.run(
        this.calcObject.job,
        this.calcObject.ptMap,
        this.calcObject.trMap,
        {},
        this.$store.getters.getTotalReal
      );

      // this.isCalcValue = this.isCalcData.props[this.currentUpl.ptAddr].ptVal;
    },
    onInsertUpl(newUpl) {
      this.workflowMgr.workgroup.workflows.forEach((workflow) => {
        // workflowMgr에서 현재 workflow 찾기
        if (workflow.name == this.currentWorkflow.name) {
          if (newUpl.uplType == "Group") {
            // 현재 workflow에 새로 추가하는 upl이 그룹일경우
            workflow.workflow.children = workflow.workflow.children.concat([
              newUpl,
            ]);
          } else {
            // 현재 workflow에 새로 추가하는 upl이 point나 var일경우
            workflow.workflow.children.forEach((v) => {
              if (v.name == this.currentUpl.name) {
                v.children = v.children.concat([newUpl]);
              }
            });
          }
        }
      });
    },
  },
};
</script>
