// Global components
// https://kr.vuejs.org/v2/guide/components-custom-events.html
// https://medium.com/returnvalues/vue-component-datepicker-83abd5e0bb5f
// let { isEmpty, parseBool } = global.xe;

import Vue from "vue";

export default {
  use() {
    Vue.component("ln-picker", {
      inheritAttrs: false,
      props: {
        // value: String,
        label: String,
        setting: Object,
        placeholder: String,
      },
      computed: {
        inputListeners() {
          let vm = this;
          return Object.assign({}, this.$listeners, {
            input: function(event) {
              vm.$emit("input", event);
            },
          });
        },
        config() {
          return this.merge(
            {},
            {
              format: "YYYY-MM-DD HH:mm",

              ignoreReadonly: true,
              // useCurrent: false,
              // showClear: false,
              // showClose: false,
              // minDate: new Date(2018, 1, 1),
              // maxDate: false,
              // showTodayButton: false,

              // inline:false,

              icons: {
                time: "far fa-clock",
                date: "fas fa-calendar",
                up: "fas fa-chevron-up",
                down: "fas fa-chevron-down",
                previous: "fas fa-chevron-left xe-dt-prev",
                next: "fas fa-chevron-right xe-dt-next",
                today: "fas fa-calendar-check",
                clear: "far fa-trash-alt",
                close: "fas fa-times",
              },
            },
            
            this.setting
          );
        },
      },

      // <date-picker class="form-control" v-bind="$attrs" v-on="inputListeners" :value="value"

      // time: <i class="glyphicon glyphicon-time"></i> <br />
      // date: <i class="glyphicon glyphicon-calendar"></i> <br />
      // up: <i class="glyphicon glyphicon-chevron-up"></i> <br />
      // down: <i class="glyphicon glyphicon-chevron-down"></i> <br />
      // previous: <i class="glyphicon glyphicon-chevron-left"></i> <br />
      // next: <i class="glyphicon glyphicon-chevron-right"></i> <br />
      // today: <i class="glyphicon glyphicon-screenshot"></i> <br />
      // clear: <i class="glyphicon glyphicon-trash"></i> <br />
      // close: <i class="glyphicon glyphicon-remove"></i> <br />

      // time:       <i :class="[ condition.config.icons.time ]"></i> <br />
      // date:       <i :class="[ condition.config.icons.date ]"></i> <br />
      // up:         <i :class="[ condition.config.icons.up ]"></i> <br />
      // down:       <i :class="[ condition.config.icons.down ]"></i> <br />
      // previous:   <i :class="[ condition.config.icons.previous ]"></i> <br />
      // next:       <i :class="[ condition.config.icons.next ]"></i> <br />
      // today:      <i :class="[ condition.config.icons.today ]"></i> <br />
      // clear:      <i :class="[ condition.config.icons.clear ]"></i> <br />
      // close:      <i :class="[ condition.config.icons.close ]"></i> <br />

      // onStartChange(e) {
      //   this.$set(this.configs.end, "minDate", e.date || null);
      // },
      // onEndChange(e) {
      //   this.$set(this.configs.start, "maxDate", e.date || null);
      // },
      // v-on:dp-change="onStartChange"  // start가 바뀌면, end가 start보다 커야하는데... 이를 맞춰주는 기능...
      // v-on:dp-change="onEndChange"
      // config: {
      //   format: "YYYY-MM-DD HH:mm",
      //   useCurrent: false,
      //   showClear: true,
      //   showClose: true,
      //   minDate: new Date(2018, 1, 1),
      //   maxDate: false,
      // }
      // vue-bootstrap-datetimepicker  VueDateTimePicker
      template: `
        <div class="form-group m-r-10">
          <label class="m-r-5"> <trans>{{ label }}</trans> </label>
          <div class="hide"> {{ setting }} </div>
          <div style="position: relative">
            <date-picker class="form-control" v-bind="$attrs" v-on="inputListeners"
            :placeholder="$t(placeholder)" 
            readonly="readonly"
            :config="config"
            />
          </div>
        </div>
      `,
    });

    let xeBaseComponent = Vue.component("base-component", {
      computed: {
        inputListeners() {
          let vm = this;
          return Object.assign({}, this.$listeners, {
            change: function(event) {
              // console.log("inputListeners change=", event)
              vm.$emit("change", event.target.value);
            },
            input: function(event) {
              // console.log("inputListeners input=", event)
              vm.$emit("input", event.target.value);
            },
          });
        },
      },
    });

    // TODO: v-model 값이 문자열이 되어 버림..
    Vue.component("ln-select", {
      extends: xeBaseComponent,
      inheritAttrs: false,
      props: {
        value: [String, Boolean],
        label: String,
        items: [Array, Object],
        all: String,
      },
      computed: {
        isAll() {
          return this.all == "false" ? false : true;
        },
      },
      // <option v-for="(item, i) in items" :key="i" :value="item"> {{ item.capitalize() }} </option>
      // <option  v-for="(key, i) in Object.keys(items)" :key="i" :value="key"> {{ (''+items[key]).capitalize() }} </option>
      template: `
      <div class="form-group m-r-10">
        <label class="m-r-5"> <trans>{{ label }}</trans>: </label>
        <select class="form-control" :value="value" v-bind="$attrs" v-on="inputListeners">
          <option v-if="isAll" value=""> {{$t('전체')}} </option>
          <template v-if="items && Array.isArray(items)">
            <option v-for="(item, i) in items" :key="i" :value="item" class="first-upper"> {{ item }} </option>
          </template>
          <template v-else-if="items && typeof(items) == 'object'">
            <option  v-for="(key, i) in Object.keys(items)" :key="i" :value="key" class="first-upper"> {{ items[key] }} </option>
          </template>
        </select>
      </div>
      `,
    });

    Vue.component("new-ln-select", {
      extends: xeBaseComponent,
      inheritAttrs: false,
      props: {
        value: [String, Boolean],
        items: [Array, Object],
        all: String,
      },
      computed: {
        isAll() {
          return this.all == "false" ? false : true;
        },
      },
      template: `
        <select class="form-control" :value="value" v-bind="$attrs" v-on="inputListeners" style="margin-right: 10px;">
          <option v-if="isAll" value=""> {{$t('전체')}} </option>
          <template v-if="items && Array.isArray(items)">
            <option v-for="(item, i) in items" :key="i" :value="item" class="first-upper"> {{ item }} </option>
          </template>
          <template v-else-if="items && typeof(items) == 'object'">
            <option  v-for="(key, i) in Object.keys(items)" :key="i" :value="key" class="first-upper"> {{ items[key] }} </option>
          </template>
        </select>
      `,
    });

    // https://kr.vuejs.org/v2/guide/components-custom-events.html
    Vue.component("xe-checkbox", {
      model: {
        prop: "checked",
        event: "change",
      },
      props: {
        checked: Boolean,
        label: String,
        tooltip: String,
      },
      template: `<div class="checkbox checkbox-css" v-b-tooltip.hover :title="$t(tooltip)">
      <input type="checkbox" v-bind:checked="checked" v-on:change="$emit('change', $event.target.checked)" :id="'xe-checkbox_' + _uid" />
      <label :for="'xe-checkbox_' + _uid"> {{$t(label)}} </label>
    </div>`,
    });

    Vue.component("xe-checkbox", {
      model: {
        prop: "checked",
        event: "change",
      },
      props: {
        checked: Boolean,
        label: String,
        tooltip: String,
      },
      template_xx: `<div class="checkbox checkbox-css" v-b-tooltip.hover :title="$t(tooltip)">
      <input type="checkbox" v-bind:checked="checked" v-on:change="$emit('change', $event.target.checked)" :id="'xe-checkbox_' + _uid" />
      <label :for="'xe-checkbox_' + _uid"> {{$t(label)}} </label>
    </div>`,
      template: `
    <div class="custom-control custom-switch ml-auto p-t-5 m-l-10" v-b-tooltip.hover :title="$t(tooltip)">
      <input type="checkbox" v-bind:checked="checked" v-on:change="$emit('change', $event.target.checked)" class="custom-control-input" :id="'xe-checkbox_' + _uid" />
      <label class="custom-control-label" :for="'xe-checkbox_' + _uid"> {{$t(label)}} </label>
    </div>
    `,
    });

    Vue.component("ln-textin", {
      extends: xeBaseComponent,
      inheritAttrs: false,
      props: {
        value: String,
        label: String,
        placeholder: String,
      },
      // computed: {
      //   isAll() {
      //     return this.all == "false" ? false : true;
      //   },
      // },
      template: `
      <div class="form-group m-r-10">
        <label class="m-r-5"> <trans>{{ label }}</trans>: </label>
        <input type="text" class="form-control" style="min-width:200px;" :placeholder="$t(placeholder)" v-bind="$attrs" v-on="inputListeners" :value="value" />
      </div>
      `,
    });

    Vue.component("ln-button", {
      extends: xeBaseComponent,
      props: {
        label: String,
        tooltip: String,
        color: String,
        icon: String,
        size: String,
      },
      template: `
      <button class="btn m-r-10" :class="[ color ? 'btn-'+color : 'btn-default', size ? 'btn-'+size : 'btn-sm' ]" v-b-tooltip.hover :title="$t(tooltip)" v-bind="$attrs" v-on="inputListeners">
        <i class="fa" :class="[ icon ]"></i> 
        {{$t(label)}}
      </button>
      `,
    });

    let xeBaseButton = Vue.component("base-button", {
      extends: xeBaseComponent,
      props: {
        label: String,
        tooltip: String,
        size: String,
      },
    });

    // <ln-button label="추가" color="default" icon="fa-plus" tooltip="Add new point" @click="openPointModal()" size='default' />
    // <ln-button label="조회"         color="primary" icon="fa-search" tooltip="Search point data" @click="searchPoint()" />
    // <button class="btn btn-sm btn-danger" @click="onDeleteAlarm()" v-if="isUpdate"><i class="fa fa-times"></i> {{ $t('삭제') }}</button>
    // <button class="btn btn-sm btn-primary pull-right" @click="passes(onSaveAlarm)"><i class="fa fa-save"></i> {{ $t('저장') }}</button>
    // <btn-add @click="openPointModal()" />

    Vue.component("btn-normal", {
      extends: xeBaseButton,
      props: {
        icon: String,
        color: String,
      },
      template: `
      <button class="btn m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x', color ? 'btn-'+color : 'btn-default' ]" v-b-tooltip.hover :title="$t(tooltip)" v-bind="$attrs" v-on="inputListeners">
        <i class="fa " :class="[ icon ]" ></i> 
        <trans :label="$t(label)" />
      </button>
      `,
    });

    Vue.component("btn-add", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-default m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('추가하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-plus"></i> 
        <trans :label="label || '추가'" />
      </button>
      `,
    });

    Vue.component("btn-refresh", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-primary m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('새로고침하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-sync"></i> 
        <trans :label="label || '새로고침'" />
      </button>
      `,
    });

    Vue.component("btn-search", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-primary m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('조회하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-search"></i> 
        <trans :label="label || '조회'" />
      </button>
      `,
    });

    Vue.component("btn-refresh", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-primary m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('조회하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-sync"></i> 
        <trans :label="label || '새로고침'" />
      </button>
      `,
    });

    Vue.component("btn-delete", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-danger m-r-10" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('삭제하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-times"></i> 
        <trans :label="label || '삭제'" />
      </button>
      `,
    });

    Vue.component("btn-save", {
      extends: xeBaseButton,
      template: `
      <button class="btn btn-primary m-l-10 pull-right" :class="[ size ? 'btn-'+size : 'btn-1x' ]" v-b-tooltip.hover :title="tooltip ? tooltip : $t('저장하려면 클릭하세요.')" v-bind="$attrs" v-on="inputListeners">
        <i class="fa fa-save"></i> 
        <trans :label="label || '저장'" />
      </button>
      `,
    });

    Vue.component("no-data", {
      extends: xeBaseButton,
      props: {
        height: {
          type: String,
          required: false,
          default: "100px",
        },
      },
      template: `
      <div class="vertical-box vertical-box-widget" :style="{ 'min-height': height }">
        <div class="vertical-box-column valign-middle text-center w-100">
          <b> {{ $t('데이터가 존재하지 않습니다.') }} </b>
        </div> 
      </div>
      `,
    });

    Vue.component("todo-docs", {
      props: {},
      styles: {},
      template: `
      <div class="xe-todo-docs" v-if="$store.getters.isHelpMode">
        <div v-for="(doc,i) in DOCS" :key="i">
          <div class="row">
            <div class="col-12">
              {{ doc.file }}            
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <pre>{{doc.contents}}</pre>
            </div>
          </div>
          <hr />
        </div>
      </div>
      `,
    });

    Vue.component("table-responsive", {
      extends: xeBaseComponent,
      props: {
        columns: String,
        rows: String,
      },
      template: `
      <div class="table-responsive">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :search-options="{ enabled: false, placeholder: 'Search this table' }"
          :pagination-options="{ enabled: true, position: 'bottom', perPage: 20, perPageDropdown: [20, 100, 500], rowsPerPageLabel: 'Rows', mode: 'pages' }"
          :sort-options="{ enabled: true }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
          }"
          :max-height="GRID_MAX_HEIGHT"
          :fixed-header="true"
          style-class="vgt-table striped  condensed"
        >
          <div slot="emptystate" style="text-align: center;">
            조회된 결과가 없습니다.
          </div>
          <div slot="selected-row-actions">
            
            <slot name="selected-row-actions"></slot>
          </div>
          <template slot="table-row" slot-scope="props">
    
            <slot name="table-row"></slot>
    
          </template>
        </vue-good-table>
      </div>
      `,
    });
  },
};
