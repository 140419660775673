<template>
  <div>
    <div v-if="isToolbar"
      :class="[ 'tool-box', ($theme == 'transparent' ? 'bg-black-lighter' : 'bg-white'),
        { 'cursor-ew-resize': splitter.dragging },,
      ]"
    >
      <!-- <div v-if="isToolbar" class="tool-box-handle" :style="{ left: left + 'px' }">
        <button class="btn btn-sm" :class="[isBodyView ? 'btn-default' : 'btn-default']" @click="onToggleToolBox">
          <i :class="[isBodyView ? 'fa fa-times' : 'fa fa-angle-double-down']"></i>
        </button>
      </div>
      <div v-else class="tool-box-handle tool-box-pencil" :style="{ left: left + 'px' }">
        <button class="btn btn-sm" @click="onStartEdit">
          <i class="fa fa-edit"></i>
        </button>
      </div> -->
      <div class="tool-box-body" :style="[{ width: splitter.width + 'px' }, { display: isBodyView ? 'block' : 'none' }]">
        <div class="m-b-10">
          <a href="javascript:;" class="xe-tab-underline" :class="{ 'xe-tab-line-orange': tabMode == 'Widgets' }" @click="onTabMode('Widgets')"> <trans>위젯</trans> </a>
          <!-- <a href="javascript:;" class="xe-tab-underline" :class="{ 'xe-tab-line-orange': tabMode == 'Points' }" @click="onTabMode('Points')"> <trans>관제점</trans> </a> -->
        </div>
        <widget-list v-show="tabMode === 'Widgets'" class="p-l-5" />
        <!-- <point-tree v-show="tabMode === 'Points'" class="p-l-5" /> -->

        <div class="cursor-ew-resize" :class="[splitter.css, ($theme == 'transparent' ? 'bg-black-lighter' : 'bg-grey')]" :style="splitter.style" style="position:absolute;top:0px;right:0px; width:3px;height:calc(100% - 20px);" @mousedown="startDrag">
          <div>
            <!-- X: {{x}} <br /> Y: {{y}} <br /> L: {{colLeft}} <br /> R: {{colRight}} <br /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tool-box {
  z-index: 1021;
  position: fixed;
  top: 52px;
  height: 100%;
  /* background-color: #e8ebef; */
}
/* 버튼 핸들 */
.tool-box-handle {
  position: absolute;
  top: 1px;
  cursor: pointer;
}
.tool-box-pencil {
  opacity: 0;
}
.tool-box-pencil:hover {
  opacity: 1;
}

/* 배경 */
.tool-box-body {
  display: none;
  min-width: 220px;
  max-width: 600px;
  max-height: 937px;
  overflow-y: auto;
}

.xe-tab-underline {
  padding: 3px 6px !important;
}

/* .btn-default {
  background-color: #e8ebef !important;
} */
</style>

<script>
//import PageOptions from "../../config/PageOptions.vue";
import WidgetList from "./WidgetList.vue";
// import PointTree from "./PointTree.vue";

export default {
  components: {
    WidgetList,
    // PointTree,
  },
  name: "ToolBox",
  data() {
    return {
      //pageOptions: PageOptions,

      isBodyView: false,
      tabMode: "Widgets",

      // splitter
      splitter: {
        dragging: false,
        x: "no",
        y: "no",
        width: 220,
        style: {},
        isClearSelection: false,
      },
      left: 185,
    };
  },
  computed: {
    isToolbar() {
      return this.$store.getters.isToolbar;
    },
  },
  watch: {
    isToolbar: function(newV) {
      this.isBodyView = newV;
    },
  },
  mounted() {
    window.addEventListener("mouseup", this.stopDrag);
    window.addEventListener("mousemove", this.doDrag);
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.stopDrag);
    window.removeEventListener("mousemove", this.doDrag);
  },
  methods: {
    // onStartEdit() {
    //   this.$store.commit("UPDATE_EDIT_MODE", true);
    //   this.onToggleToolBox();
    // },
    onToggleToolBox() {
      this.isBodyView = !this.isBodyView;
    },
    onTabMode(mode) {
      this.tabMode = mode;
    },
    // splitter
    startDrag() {
      this.splitter.isClearSelection = true;
      this.splitter.dragging = true;
      this.splitter.x = this.splitter.y = 0;
    },
    stopDrag() {
      this.splitter.isClearSelection = false;
      this.splitter.dragging = false;
      this.splitter.x = this.splitter.y = "no";

      this.splitter.style.backgroundColor = "rgba(239, 242, 247, 0.9)";
    },
    doDrag(evt) {
      if (this.splitter.dragging) {
        this.splitter.style.backgroundColor = "#348fe2";

        if (this.splitter.isClearSelection) this.clearSelection();

        this.splitter.width = evt.clientX;
      }
    },
  },
};
</script>
