<template>
	<vvo v-slot="v" :key="parentFacilityIdx + facilityIdx">
		<!--     
              부모키   자신키   부모정보,ct 
  장비추가 -   x        x        x
  장비수정 -   x        o        x
  하위추가 -   o        x        o
  하위수정 -   o        o        o
   -->
		<!-- parentFacilityIdx : {{ parentFacilityIdx }} <br />
channelType : {{ channelType }} -->

		<!-- 헤더 -->
		<div class="row form-group">
			<div class="col detail-title">
				<span class="title">{{ newFacility.facilityName }}</span>
				<span class="badge title"> {{ newFacility.facilityAlias }} </span>
				<span class="badge id"> {{ newFacility.facilityIdx }} </span>
				<span class="badge option"> {{ meterTypeCode }} </span>
				<span class="badge option"> {{ measTypeCode }} </span>
			</div>
		</div>

		<!-- 공통 시작 -->
		<div class="row form-group">
			<div class="col-lg-6">
				<label v-if="parentFacilityIdx">
					<trans>수집항목 선택</trans>
				</label>
				<label v-else>
					<trans>드라이버 선택</trans>
				</label>
				<!-- 신규장비만 드라이버 선택 가능 : 장비 수정, 하위장비 추가, 수정시 모두 선택불가능 -->
				<select class="form-control" v-model="newFacility.driverType"
					:disabled="isUpdate || !isEmpty(parentFacilityIdx)">
					<option v-for="(driverType, i) in driverTypes" :key="i" :value="driverType">{{ driverType == "" ?
						$t("드라이버 선택") : driverType }}</option>
				</select>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>버전</trans>
				</label>
				<select class="form-control" v-model="newFacility.version"
					:disabled="isUpdate || !isEmpty(parentFacilityIdx)">
					<option v-for="(driver, i) in getVersion" :key="i" :value="driver.version">{{ driver.version }}
					</option>
				</select>
			</div>
			<div class="col-lg-3">
				<label>관리 옵션</label>
				<div class="btn-group d-flex justify-content-between align-items-center">
					<!-- <input type="checkbox" :id="'facilityDetail_' + newFacility.facilityIdx"
						v-model="newFacility.isUse" />
					<label :for="'facilityDetail_' + newFacility.facilityIdx">
						<trans>사용여부</trans>
					</label> -->

					<button type="button" class="btn" :class="newFacility.isUse? 'btn-warning' : 'btn-light'"  @click="newFacility.isUse = !newFacility.isUse">
						{{ newFacility.isUse? '사용중' : '사용중단' }}
					</button>
				</div>
			</div>

		</div>
		<!-- 공통 끝 -->

		<!-- 계측장치 시작 -->
		<div class="row form-group" v-if="!parentFacilityIdx">
			<div class="col-lg-4" v-if="!parentFacilityIdx">
				<label>
					<trans>계측기명</trans>
				</label>
				<valid-input :vid="'계측기명'" :inputType="'text'" :inputValue.sync="newFacility.facilityName"
					:rules="rules.COLLECT_DATA_MEASUING_ID_RULE"
					:errorMessage="validMessage.COMMON_ALPHA_NUM_LENGTH_VALID_MESSAGE(1, 30)"
					:placeholder="$t('계측기명 입력')" :isMessage="true"
					@inputMethod="newFacility.facilityName = newFacility.facilityName.toUpperCase()"></valid-input>
			</div>
			<div class="col-lg-5" v-if="!parentFacilityIdx">
				<label>
					<trans>계측기 표시명</trans>
				</label>
				<valid-input :vid="'표시 계측기명'" :inputType="'text'" :inputValue.sync="newFacility.facilityAlias"
					:rules="rules.COLLECT_DATA_MEASUING_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)" :placeholder="$t('화면 표시명 입력')"
					:isMessage="true" @inputMethod="inputFacilityAlias"></valid-input>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>장치 고유번호</trans>
				</label>
				<valid-input :vid="'장치 고유번호'" :inputType="'number'" :inputValue.sync="newFacility.deviceId"
					:rules="rules.COLLECT_DATA_EQUIP_UNIQUE_NUM_RULE" :placeholder="$t('장치 고유번호')" :NonStatus="true"
					:disabled="!isEmpty(parentFacilityIdx) "></valid-input>
			</div>
		</div>

		<div class="row form-group" v-if="!parentFacilityIdx">
			<div class="col-lg-4">
				<label>
					<trans>계측기 유형</trans>
				</label>
				<select class="form-control" v-model="newFacility.meterTypeCode">
					<option :value="null">{{ $t('선택') }}</option>
					<!-- <option v-for="(meterType, i) in getMeterTypeList" :key="i" :value="meterType.smallCode">{{
						meterType.smallCodeName }}</option> -->
					<option v-for="code in $store.state.commonCodes.meterTypeCode" :key="code.value"
						:value="code.value">{{
						code.text }}</option>
				</select>
			</div>
			<div class="col-lg-4">
				<label>
					<trans>측정방법</trans>
				</label>
				<select class="form-control" v-model="newFacility.measTypeCode">
					<option :value="null">{{ $t('선택') }}</option>
					<!-- <option v-for="(meterType, i) in getMeterTypeList" :key="i" :value="meterType.smallCode">{{
						meterType.smallCodeName }}</option> -->
					<option v-for="code in $store.state.commonCodes.measTypeCode" :key="code.value" :value="code.value">
						{{
						code.text }}</option>
				</select>
			</div>
			<div class="col-lg-4">
				<label>
					<trans>적용구역</trans>
				</label>
				<select class="form-control" v-model="newFacility.applyZoneIdx">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.zoneInfo" :key="code.value" :value="code.value"> {{ code.text }}
					</option>
				</select>
			</div>
		</div>

		<div class="row form-group" v-if="!parentFacilityIdx">
			<div class="col-lg-6">
				<label>
					<trans>설치일자</trans>
				</label>
				<input type="text" class="form-control" :class="classes" :placeholder="$t('YYYY-MM-DD')"
					v-model="newFacility.instDate" />
			</div>
			<div class="col-lg-6">
				<label>
					<trans>검교정 주기</trans>
				</label>
				<select class="form-control" v-model="newFacility.clbrPeriodCode">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.clbrPeriodCode" :key="code.value"
						:value="code.value">{{ code.text }}</option>
				</select>
			</div>
		</div>
		<!-- 계측장치 끝 -->

		<!-- 서브모듈 시작 -->
		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-12">
				<div v-if="facilityIdx" class="alert alert-secondary fade show">'{{ parentFacility.facilityName }}' {{
					$t("의 서브모듈 입니다.") }}</div>
				<div v-else class="alert alert-secondary fade show">
					'{{ parentFacility.facilityName }}' {{ $t("의 서브모듈로 등록됩니다.") }}
				</div>
			</div>
		</div>

		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-3">
				<label>
					<trans>모듈번호</trans> (CT)
				</label>
				<valid-input :vid="'모듈번호'" :inputType="'number'" :inputValue.sync="newFacility.ct"
					:rules="rules.COLLECT_DATA_MODULE_NUM_RULE"
					:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(1, 999)" :placeholder="$t('모듈번호 지정')"
					:isMessage="true"></valid-input>
			</div>


			<div class="col-lg-4"> <label>
					<trans>모듈명</trans>
				</label>
				<valid-input :vid="'서브 모듈명'" :inputType="'text'" :inputValue.sync="newFacility.facilityName"
					:rules="rules.COLLECT_DATA_MODULE_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)" :placeholder="$t('서브 모듈명 입력')"
					:isMessage="true"></valid-input>
			</div>
			<div class="col-lg-5"> <label>
					<trans>모듈 표시명</trans>
				</label>
				<valid-input :vid="'표시 서브 모듈명'" :inputType="'text'" :inputValue.sync="newFacility.facilityAlias"
					:rules="rules.COLLECT_DATA_MODULE_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
					:placeholder="$t('서브 모듈 표시명 입력')" :isMessage="true" @inputMethod="inputFacilityAlias"></valid-input>
			</div>

		</div>

		<div class="row form-group" v-if="parentFacilityIdx && parentFacility.driverType != 'KieMecs.Mqtt'">

			<div class="col-lg-6">
				<label>{{ $t('외부 데이터 매핑키') }}</label>
				<valid-input :vid="'외부 데이터 매핑키'" :inputType="'text'" :inputValue.sync="newFacility.mappingKey"
					:rules="rules.COLLECT_DATA_MAPPING_KEY_RULE" :placeholder="$t('데이터 매핑키 입력')"
					:NonStatus="true"></valid-input>
			</div>
			<div class="col-lg-3" v-if="parentFacility.measTypeCode == 'Pulse'">
				<label>펄스당 용량</label>
				<select class="form-control" v-model="newFacility.facilityOption.pulseUnit" :ref="'pulseUnit'"
					@input="setPulseUnit">
					<option :value="null">{{ $t('선택') }}</option>
					<option value="0.001">0.001</option>
					<option value="0.01">0.01</option>
					<option value="0.1">0.1</option>
					<option value="1">1</option>
					<option value="10">10</option>
					<option value="100">100</option>
					<option value="1000">1000</option>
				</select>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>적용 구역</trans>
				</label>
				<select class="form-control" v-model="newFacility.applyZoneIdx">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.zoneInfo" :key="code.value" :value="code.value"> {{ code.text }}
					</option>
				</select>
			</div>
		</div>
		<div class="row form-group" v-if="parentFacilityIdx && parentFacility.driverType == 'KieMecs.Mqtt'">

			<div class="col-lg-4">
				<label>{{ $t('GS SYSTEM ID') }}</label>
				<valid-input :vid="'GS SYSTEM ID'" :inputType="'text'" :inputValue.sync="newFacility.facilityOption.gsSystemId"
					:rules="rules.COLLECT_DATA_MAPPING_KEY_RULE" :placeholder="$t('데이터 매핑키 입력')"
					:NonStatus="true"></valid-input>
			</div>
			<div class="col-lg-4">
				<label>{{ $t('SC SYSTEM ID') }}</label>
				<valid-input :vid="'SC SYSTEM ID'" :inputType="'text'" :inputValue.sync="newFacility.facilityOption.scSystemId"
					:rules="rules.COLLECT_DATA_MAPPING_KEY_RULE" :placeholder="$t('데이터 매핑키 입력')"
					:NonStatus="true"></valid-input>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>적용 구역</trans>
				</label>
				<select class="form-control" v-model="newFacility.applyZoneIdx">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.zoneInfo" :key="code.value" :value="code.value"> {{ code.text }}
					</option>
				</select>
			</div>
		</div>

		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-4">
				<label>
					<trans>사용 에너지원</trans>
				</label>
				<select class="form-control" v-model="newFacility.energySourceCode">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.energySourceCode" :key="code.value"
						:value="code.value"> {{ code.text }}</option>
				</select>
			</div>
			<div class="col-lg-4">
				<label>{{ $t('사용용도') }}</label>
				<select class="form-control" v-model="newFacility.usageCode">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.phscPointUsage" :key="code.value"
						:value="code.value"> {{ code.text }}</option>
				</select>
			</div>
			<div class="col-lg-4">
				<label>{{ $t('에너지 공급원 구분') }}</label>
				<select class="form-control" v-model="newFacility.energySplyTypeCode"
					:disabled="!(newFacility.usageCode === 'Panel')">
					<option :value="null">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.energySplyTypeCode" :key="code.value"
						:value="code.value"> {{ code.text }}</option>
				</select>
			</div>

		</div>



		<!-- 공통 푸터 (remark) -->

		<div class="row form-group">
			<div class="col-lg-12">
				<label>
					<trans>비고</trans>
				</label>
				<textarea class="form-control" :placeholder="$t('비고')" v-model="newFacility.remark" rows="3"></textarea>
			</div>
		</div>

		<!-- 계측기 정보 추가 끝 -->
		<div slot="modal-footer" class="w-100">
			<button v-xe-pm.E class="btn btn-danger" v-if="isUpdate && (isEmpty(childrens) || childrens == 0)"
				@click="onDeleteFacility()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
			<button class="btn btn-primary pull-right" @click="onValidate(v, onSaveFacility, newFacility)"><i
					class="fa fa-save"></i> {{
				$t("저장") }}</button>
		</div>
	</vvo>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";

export default {
	props: ["serverIdx", "channelIdx", "parentFacilityIdx", "facilityIdx", "channelType", "childrens", "save-done", "newCT"],
	data() {
		return {
			classes: "", // 오류..
			parentFacility: {},
			newFacility: {},
			orgFacility: {},
			aliasSynced: true,
			//driverList: [],
			drivers: [],
			rules,
			validMessage,
			popupMessages,
			controlMessages,
			
			facilityOption: {
				pulseUnit: null
			},
		};
	},
	computed: {
		isUpdate() {
			return !this.isEmpty(this.facilityIdx);
		},
		driverTypes() {
			// version이 여러개면, driverType이 중복되므로 distinct 해준다.
			return this.drivers.map((v) => v.driverType).unique();
		},
		getVersion() {
			return this.drivers.filter((driver) => driver.driverType == this.newFacility.driverType);
		},
		meterTypeCode() {
			try {
				const result = this.$store.state.commonCodes.meterTypeCode.find(item => item.value == this.newFacility.meterTypeCode);
				// console.log(`find energySourceCode(${this.detailInfo.energySourceCode}) :`, result);
				return result.text ?? '-';
			} catch (err) {
				return '-';
			}
		},
		measTypeCode() {
			try {
				const result = this.$store.state.commonCodes.measTypeCode.find(item => item.value == this.newFacility.measTypeCode);
				// console.log(`find energySourceCode(${this.detailInfo.energySourceCode}) :`, result);
				return result.text ?? '-';
			} catch (err) {
				return '-';
			}
		},
	},
	watch: {
		facilityIdx: function () {
			this.initSetting();
		},
		'newFacility.facilityName': function (newVal) {
			if (this.aliasSynced) this.newFacility.facilityAlias = newVal;
		},
	},
	created() {
	},
	async mounted() {
		await this.initSetting();
	},
	methods: {
		setPulseUnit() {
			// this.newFacility.facilityOption = this.facilityOption;
		},
		driversClear() {
			this.drivers.clear();
		},
		inputFacilityAlias() {
			console.log('aliasSynced set to : ', this.aliasSynced);
			this.aliasSynced = false;
		},
		initSetting() {

			this.newFacility = {
				serverIdx: this.serverIdx,
				channelIdx: this.channelIdx,
				parentFacilityIdx: this.parentFacilityIdx,
				facilityOption: {},
				driverType: "",
				deviceId: "",
				mappingKey: "",
				facilityName: "",
				applyZoneIdx: null,
				energySourceCode: null,
				energySplyTypeCode: null,
				isUse: true,
				instDate: new Date().toISOString().slice(0, 10),
				clbrPeriodCode: "A006",
			};

			// Pulse형 계측기는 pulseUnit을 관리해야 함.
			if (this.parentFacility.measTypeCode == 'Pulse') {
				this.newFacility.facilityOption.pulseUnit = null;
			}

			this.drivers.clear();

			// 장비 드라이버 먼저 조회
			backEndApi.driver.searchDriver().then(({ data }) => {
				console.log(data);
				if (this.$err(data)) return;
				if (this.isEmpty(this.channelType)) return;
				this.drivers.range(data.filter((v) => v.channelType == this.channelType));

				this.drivers.unshift({
					channelType: "",
					driverName: "",
					driverType: "",
					remark: "",
					version: "",
				});

				if (this.drivers.length == 0) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_INIT_ERROR_POPUP_MESSAGE);
					return;
				}

				// 장비 상세 정보 조회
				if (this.isUpdate) {
					backEndApi.facility.getFacility(this.facilityIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.newFacility = data;
						// this.newFacility.facilityOption = {};
						try {
							if (typeof (data.facilityOption) === 'string')
								this.newFacility.facilityOption = JSON.parse(data.facilityOption);
						} catch (err) {
							console.error('Invalid Json Format', err);
						}
						// this.facilityOption = this.newFacility.facilityOption;
						
						this.newFacility.applyZoneIdx = data.applyZoneIdx ;
						this.newFacility.energySourceCode = data.energySourceCode;
						this.newFacility.energySplyTypeCode = data.energySplyTypeCode;

						this.orgFacility = Object.freeze(data);

						this.aliasSynced = (this.newFacility.facilityName == this.newFacility.facilityAlias);

						this.bindParentDirver();
					});
				} else {
					this.bindParentDirver();
				}
			});

			//계측기 코드 조회 추가 2022-12-12 권철규
			// backEndApi.meterType.getMeterTypeList().then(({ data }) => {
			// 	if (this.$err(data)) return;
			// 	this.meterTypeList.range(data);
			// });

			// tb_meter_type -> tb_small_code로 사용 코드 변경 2024-01-22 by badblock
			// backEndApi.publicCode.getSmallCodeList("meterTypeCode", "", "Y").then(({ data }) => {
			// 	if (this.$err(data)) return;
			// 	this.meterTypeList.range(data);
			// });

			// 용도 코드 조회 수정
			// backEndApi.publicCode.getSmallCodeList("phscPointUsage").then(({ data }) => {
			// 	let dataArray = [];
			// 	if (this.$err(data)) return;

			// 	for (const code of data) {
			// 		if (code.enabled.toUpperCase() == "Y") {
			// 			let dataDvsn = {};
			// 			dataDvsn.smallCode = code.smallCode;
			// 			dataDvsn.smallCodeName = code.smallCodeName;
			// 			dataArray.push(dataDvsn);
			// 		}
			// 	}

			// 	this.usageCodeList.range(dataArray);
			// });

			//검교정 주기 조회 추가 2022-12-12 권철규
			// backEndApi.publicCode.getSmallCodeList("clbrPeriodCode", "", "Y").then(({ data }) => {
			// 	if (this.$err(data)) return;
			// 	this.clbrPeriodList.range(data);
			// });

			/**
			 * NOTE - 새로운 ct 값이 존재할 경우에만 추가
			 */
			if (this.newCT) {
				this.newFacility.ct = this.newCT;
			}

			// backEndApi.publicCode.getSmallCodeList("energySourceCode").then(({ data }) => {
			// 	if (this.$err(data)) return;
			// 	this.energySourceCodeList.range(data);
			// });

			// backEndApi.publicCode.getSmallCodeList("energySplyTypeCode").then(({ data }) => {
			// 	if (this.$err(data)) return;
			// 	this.energySplyTypeCodeList.range(data);
			// });
		},
		bindParentDirver() {
			// 하위 장비 등록시 부모정보 조회
			if (this.parentFacilityIdx) {
				backEndApi.facility.getFacility(this.parentFacilityIdx).then(({ data }) => {

					// 하위장비 추가인경우, 부모 드라이버 정보를 변경할 수 없다...
					// TODO: 시간차 오류가 있을 수 있음..
					if (this.$err(data)) return;
					this.parentFacility = data;
					this.newFacility.driverType = data.driverType;
					this.newFacility.version = data.version;
					this.newFacility.deviceId = data.deviceId;
				});
			}
		},
		onSaveFacility() {
			if (this.isEmpty(this.newFacility.driverType) || this.isEmpty(this.newFacility.version)) {
				this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_DRIVER_VALID_POPUP_MESSAGE);
				return;
			}

			const regex = /^[A-Za-z0-9\-._]+$/;

			if (this.newCT === undefined) {
				if (!regex.test(this.newFacility.facilityName.toUpperCase())) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_ID_VALID_POPUP_MESSAGE);
					return;
				}

				if (this.isEmpty(this.newFacility.facilityAlias)) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_NAME_VALID_POPUP_MESSAGE);
					return;
				}
			}

			console.log('isUpdate: ', this.isUpdate);

			this.isUpdate ? this.updateFacility() : this.insertFacility();
		},
		insertFacility() {
			if (typeof (this.newFacility.facilityOption) !== 'string')
				this.newFacility.facilityOption = JSON.stringify(this.newFacility.facilityOption);


			// TODO: 하위장비 등록시 다른 ct 번호와 중복되는지 확인해야 함.
			// 이미 존재하는 ct는 입력 할 수 없어야 함.
			this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;
				backEndApi.facility.insertFacility(this.newFacility).then(({ data }) => {
					if (this.$err(data)) return;

					this.newFacility = {};
					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},
		updateFacility() {

			// TODO: 하위장비 변경시 ct가 변경되면, 다른 ct 번호와 중복되는지 확인해야 함.
			// 이미 존재하는 ct는 입력 할 수 없어야 함.
			// TODO: 장비의 드라이버가 변경되면, 하위장비의 드라이버 정보도 전부 변경되어야 한다.
			// if(this.newFacility.facilityOption);

			// 원래의 정보와 driverType이 변경되면...
			console.log('updateFacility: ', this.newFacility.driverType, this.orgFacility.driverType, this.newFacility.version, this.orgFacility.version);
			if (this.newFacility.driverType !== this.orgFacility.driverType || this.newFacility.version !== this.orgFacility.version) {
				this.alertNoti(popupMessages.POINT_DATA_COLLECTION_DRIVER_UPDATE_ERROR_POPUP_MESSAGE);
				return;
			}

			if (this.newFacility.facilityName !== this.orgFacility.facilityName) {
				this.alertNoti(popupMessages.POINT_DATA_COLLECTION_PTADDR_CHANGE_POPUP_MESSAGE);
			}

			if (typeof (this.newFacility.facilityOption) !== 'string') {
				this.newFacility.facilityOption = JSON.stringify(this.newFacility.facilityOption);
			}

			this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				backEndApi.facility.updateFacility(this.newFacility).then(({ data }) => {
					if (this.$err(data)) return;
					this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},
		onDeleteFacility() {
			// TODO: 하위 정보가 있으면 삭제할 수 없습니다.
			// TOOD: childrens 인자로 받지 않고, 서버에서 조회해야 정확함..

			this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				backEndApi.facility.deleteFacility(this.newFacility.facilityIdx).then(({ data }) => {
					if (this.$err(data)) return;
					this.newFacility = {};
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},

		loadOptions({ callback }) {
			callback();
		},
	},
};
</script>

<style scoped>
.detail-title {
	padding: 0 !important;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 1rem;
	font-weight: 600;
}

.title {
	font-size: 14px;
	font-weight: 600;
	margin-left: 10px;
	margin-right: 10px
}

.badge {
	font-size: 12px;
	font-weight: 500;
	margin-right: 4px;
	display: inline-block;
	min-width: 20px;
	padding: 0px 10px 0px 10px;
	color: #fff;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	/* background-color: #777; */
	line-height: 22px;
	border-radius: 8px;
}

.badge.title {
	background-color: #FF6D00;
	color: #fff;
}

.badge.id {
	background-color: #546E7A;
	color: #fff;
}

.badge.option {
	background-color: #CFD8DC;
	color: #000;
}

.hr {
	box-sizing: content-box;
	overflow: visible;
	/* margin-top: 10px; */
	/* margin-bottom: 10px; */
	border-top: 1px solid black;
	border: 0;
	height: 1px;
	background: rgba(0, 0, 0, .1);
}
</style>