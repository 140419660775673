<template>
	<vvo v-slot="{ passes }">
		<div class="row form-group">
			<div class="col-lg-6">
				<label v-b-tooltip.hover :title="'NO. ' + newPoint.ptIdx">
					<trans>관제점 주소</trans>
					<!-- <span class="badge m-l-10 bg-lime" v-if="newPoint.isVirtual"> Virtual </span>
          <span class="badge m-l-10 bg-orange" v-if="newPoint.ptType === 'SUM'"> Sum </span> -->
				</label>
				<!-- |not-start-number  숫자로 시작할 수 없습니다. -->
				<vvp rules="required|min:8|max:32|special" v-slot="{ errors, classes }" name="Point Address">
					<input type="text" :readonly="!isEditable" class="form-control" :class="classes"
						:placeholder="$t('관제점 주소를 입력하세요.')" v-model="newPoint.ptAddr" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
			<div class="col-md-3">
				<label>
					<trans>관제점 ID</trans>
				</label>
				<input type="text" class="form-control" :placeholder="$t('관제점 ID')" v-model="newPoint.ptIdx"
					:readonly="!isEditable" />
			</div>
			<div class="col-md-3">
				<label for="enabled">
					<trans>사용 여부</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.enabled" class="form-control" id="enabled" :disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">{{
						code.text }}</option>
				</select>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-lg-3">
				<label>
					<trans>관제점 그룹</trans>
				</label>
				<input type="text" class="form-control" :placeholder="$t('관제점 그룹을 입력하세요.')" v-model="newPoint.ptGroup"
					:readonly="!isEditable" />
			</div>
			<div class="col-lg-6">
				<label>
					<trans>관제점명</trans>
				</label>
				<!--  TODO : 관제점명 체크 로직 정리 필요함. -->
				<!-- <vvp rules="required|min:2|max:100|special|not-start-number" v-slot="{ errors, classes }" name="Point Name"> -->
				<vvp rules="required|min:2|max:100" v-slot="{ errors, classes }" name="Point Name">
					<input type="text" v-focus class="form-control" :class="classes" :placeholder="$t('관제점명을 입력하세요.')"
						v-model="newPoint.ptName" :readonly="!isEditable" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
			<div class="col-md-3">
				<label for="isVirtual">
					<trans>관제점 형태</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.isVirtual" class="form-control" id="isVirtual" :disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.isVirtual" :key="code.value" :value="code.value">{{
						code.text }}</option>
				</select>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-4">
				<label for="purpsUseCode">
					<trans>활용 목적</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.purpsUseCode" class="form-control" id="purpsUseCode" :disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.purpsUseCode" :key="code.value" :value="code.value">
						{{ code.text }}</option>
				</select>
			</div>
			<div class="col-md-4">
				<label for="dataSourceCode">
					<trans>데이터 획득 유형</trans>
				</label>
				<select v-model="newPoint.dataSourceCode" class="form-control" id="dataSourceCode"
					:disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.dataSourceType" :key="code.value"
						:value="code.value">
						{{ code.text }}</option>
				</select>
			</div>
			<div class="col-md-4">
				<label for="valueTypeCode">
					<trans>값 유형</trans>
				</label>
				<select v-model="newPoint.valueTypeCode" class="form-control" id="valueTypeCode"
					:disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.valueTypeCode" :key="code.value"
						:value="code.value">{{ code.text }}</option>
				</select>
			</div>
		</div>

		<div class="row form-group">

			<div class="col-md-4">
				<label for="energySourceCode">
					<trans>사용 에너지</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.energySourceCode" class="form-control" id="energySourceCode"
					:disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.energySourceCode" :key="code.value"
						:value="code.value">
						{{ code.text }}</option>
				</select>
			</div>

			<div class="col-md-4">
				<label for="energySplyTypeCode">
					<trans>에너지 공급원 구분</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.energySplyTypeCode" class="form-control" id="energySplyTypeCode"
					:disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.energySplyTypeCode" :key="code.value"
						:value="code.value">
						{{ code.text }}</option>
				</select>
			</div>

			<div class="col-md-4">
				<label for="itemUseageCode">
					<trans>사용 용도</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-if="newPoint.isVirtual == '1'" v-model="newPoint.itemUseageCode" class="form-control"
					id="itemUseageCode" :disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.locPointUsage" :key="code.value"
						:value="code.value">
						{{ code.text }}</option>
				</select>
				<select v-else v-model="newPoint.itemUseageCode" class="form-control" id="itemUseageCode"
					:disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.phscPointUsage" :key="code.value"
						:value="code.value">
						{{ code.text }}</option>
				</select>
			</div>

		</div>

		<div class="row form-group">
			<div class="col-md-4">
				<label for="purpsUseCode">
					<trans>집계 유형</trans>
				</label>
				<!-- <input type="text" class="form-control" :placeholder="$t('사용여부')" v-model="newPoint.enabled" /> -->
				<select v-model="newPoint.ptTypeCode" class="form-control" id="ptTypeCode" :disabled="!isEditable">
					<option value="">{{ $t('선택') }}</option>
					<option v-for="code in $store.state.commonCodes.ptTypeCode" :key="code.value" :value="code.value">{{
						code.text }}</option>
				</select>
			</div>

			<div class="col-md-4">
				<!-- TODO: 단위에 W 가 있는데    W (unknown)  으로 표시됨 -->
				<label for="unitLargeCode">
					<trans>단위 유형</trans>
				</label>
				<select v-model="newPoint.unitLargeCode" id="unitLargeCode" class="form-control"
					:disabled="!isEditable">
					<option value="">선택</option>
					<option v-for="(value) in $store.state.unitTypes" :key="value.value" :value="value.value"
						id="unitLargeCode"> {{ value.text }} </option>
				</select>
			</div>
			<div class="col-md-4">
				<!-- TODO: 단위 삭제하고 저장하면, 삭제된 채로 저장되지 않음 -->
				<label for="unitSmallCode">
					<trans>단위</trans>
				</label>
				<select v-model="newPoint.unitSmallCode" id="unitSmallCode" class="form-control"
					:disabled="!isEditable">
					<option value="">선택</option>
					<option v-for="(code) in $store.state.unitCodes[newPoint.unitLargeCode]" :key="code.value"
						:value="code.value" id="unitSmallCode">
						{{ code.text }}
					</option>
				</select>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-6">
				<label>
					<trans>최소값</trans>
				</label>
				<input type="number" class="form-control" :readonly="!isEditable" :placeholder="$t('최대값을 입력하세요.')"
					v-model="newPoint.filterMinValue" />
			</div>
			<div class="col-md-6">
				<label>
					<trans>최대값</trans>
				</label>
				<input type="number" class="form-control" :readonly="!isEditable" :placeholder="$t('최대값을 입력하세요.')"
					v-model="newPoint.filterMaxValue" />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-4">
				<label>
					<trans>오버플로우</trans>
				</label>
				<input type="number" class="form-control" :readonly="!isEditable" :placeholder="$t('오버플로우값을 입력하세요.')"
					v-model="newPoint.fixValue" v-b-tooltip.hover
					:title="$t('Min, Max 범위를 벗어난 경우에 사용할 값을 지정합니다. 빈값도 가능합니다.')" />
			</div>
			<div class="col-lg-4">
				<div class="checkbox checkbox-css m-t-25" v-b-tooltip.hover
					:title="$t('데이터 입력시 이전값과 비교하여 변경된 경우에만 로그를 기록합니다.')">
					<input type="checkbox" :id="'point_checkbox_is_cov_info_' + newPoint.ptAddr"
						v-model="newPoint.isCov" :disabled="!isEditable" />
					<label :for="'point_checkbox_is_cov_info_' + newPoint.ptAddr">
						<trans>COV 여부</trans>
					</label>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="checkbox checkbox-css m-t-25" v-b-tooltip.hover
					:title="$t('데이터 입력시 0값이 입력되면 처리하지 않고, 무시합니다.')">
					<input type="checkbox" :id="'point_checkbox_is_ignore_zero_' + newPoint.ptAddr"
						v-model="newPoint.isIgnoreZero" :disabled="!isEditable" />
					<label :for="'point_checkbox_is_ignore_zero_' + newPoint.ptAddr">
						<trans>0값 무시 여부</trans>
					</label>
				</div>
			</div>
		</div>

		<div class="row form-group">

			<div class="col-md-12">
				<label for="rmk">
					<trans>관제점 설명</trans>
				</label>
				<input type="text" class="form-control" :placeholder="$t('관제점 설명')" v-model="newPoint.rmk"
					:readonly="!isEditable" />
			</div>

		</div>

		<!-- <div v-if="isEditable" class="row form-group">
			<div class="col-lg-6">
				<label>
					<trans>등록일</trans>
				</label>
				<span class="form-control" :readonly="isEditable">{{ isEmpty(newPoint.regDate) ? "" :
					newPoint.regDate.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
			<div class="col-lg-6">
				<label>
					<trans>최종수정일</trans>
				</label>
				<span class="form-control" :readonly="isEditable">{{ isEmpty(newPoint.modDate) ? "" :
					newPoint.modDate.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
		</div> -->

		<div slot="modal-footer">
			<button v-xe-pm.E v-if="!isEditable" class="btn btn-danger pull-right" v-on:click="setEditMode"><i
					class="fa fa-times"></i> 편집 </button>
			<button v-if="isEditable" class="btn btn-primary pull-right " v-on:click="passes(onSavePoint)"><i
					class="fa fa-save"></i> 저장 </button>			
		</div>

	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xelib from "xelib";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	const { Unit } = xelib.PointBox;

	// TODO: Unit 라이브러리가 실제 변환할 수 있는 것들에 대한 화면제어가 필요함.

	// Unit 라이브러리에서 type을 키로 잡아서 tree-select option을 생성함.
	const unitOptions = (function() {
		return Object.keys(Unit.types).map((type, i) => {
			let children = Object.keys(Unit.types[type]).map((v) => {
				// return { id: i}-${j, label: v };
				return { id: v, label: v };
			});

			return { id: i, label: type, children: children };
		});
	})();

	// TODO: Unit.conversion    unit과 rawUnit이 동시에 사용된 경우, conversion이 지원되는지 검사할 수 있어야 함.

	// TODO: 관제점 테이블에 입출력 direction이 없음.

	export default {
		// props: ["ptAddr", "readonly", "save-done"],
		props: {
			ptAddr: { type: String, required: true },
			readonly: { type: Boolean, required: false, default: true },
			"save-done": { type: Function, required: false },
			ptIdx: { type: Number, require: true },
		},
		data() {
			return {
				// unitTypes: [],
				isEditable: false,
				unitOptions: unitOptions,
				newPoint: {},
				controlMessages,
			};
		},
		computed: {
			hasButtonSlot() {
				return !!this.$slots.button;
			},			
		},
		watch: {
			ptAddr: function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.$store.dispatch("UPDATE_PT_MAP");

				this.newPoint = { ptGroup: "", max: 999999999999.99, min: -999999999999.99, isVirtual: false, ptType: "LST", valueType: "analog", overflow: 0, isCov: true };

				// if (this.isEditable) {
					backEndApi.pointInfo.getPoint(this.$props.ptAddr).then(({ data }) => {
						if (this.$err(data)) {
							this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
							return;
						}
						
						this.newPoint = data;

						this.newPoint.isVirtual = this.newPoint.isVirtual? "1" : "0";
						
						console.log(`isVirtual : `, this.newPoint.isVirtual);
						console.log(`dataSourceCode : `, this.newPoint.dataSourceCode);

						// undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
						if (this.isEmpty(this.newPoint.unit)) this.newPoint.unit = null;
						if (this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;
					});
				// }
			},
			onChangeUnit(value) {
				this.newPoint.unit = value.unit;
			},
			onChangeRawUnit(value) {
				this.newPoint.rawUnit = value.rawUnit;
			},
			setEditMode() {
				this.isEditable = true;
			},
			onSavePoint() {
				// this.isEditable ? this.updatePoint() : this.insertPoint();
				this.updatePoint();
			},
			insertPoint() {
				// TODO: DUP 체크 ptList 업데이트되지 않으면, 중복체크 되지 않을 수 있다.
				let liveAM = this.readStorage("liveAM");
				if (liveAM[this.newPoint.ptAddr]) {
					this.alertNoti(popupMessages.POINT_DATA_DUP_POPUP_MESSAGE);
					return;
				}

				this.newPoint.isCov = this.parseBool(this.newPoint.isCov);
				this.newPoint.overflow = this.isEmpty(this.newPoint.overflow) ? null : this.newPoint.overflow;

				this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.insertPointInfo(this.newPoint).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			updatePoint() {
				this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.updatePointInfo(this.newPoint).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			deletePoint() {
				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.deletePointInfo(this.newPoint.ptAddr).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_SCHEDULE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>
