/*********************************************
 *           입력 Validation 룰 정의           *
 *              (postfix : RULE)              *
 **********************************************/

// #region 공통 (prefix : COMMON)

/** 공통 검색어 룰 */
export const COMMON_SEARCH_WORD_RULE = "";

// #region 로그인 (prefix : LOGIN)

/** 로그인 아이디 룰 */
export const LOGIN_ID_RULE = "required|non_korean|min:5|max:30";

/** 로그인 패스워드 룰 */
export const LOGIN_PASSWORD_RULE = "required|non_korean|min:9|max:20";

// #region 로그인 (prefix : PROFILE)

/**  프로필 사용자 명 */
export const PROFILE_NAME_RULE = "required|non_special|min:1|max:30";

/** 프로필 전화번호 */
export const PROFILE_PHONE_NUM_RULE = "phone|max:30";

// #region 비밀번호 변경 (prefix : PASSWORD)

/** 현재 비밀번호 */
export const PASSWORD_CHANGE_ORIGIN = "required|password|min:9|max:20";

/** 새 비밀번호 */
export const PASSWORD_CHANGE_NEW = (id, value) => `required|confirmPass:${id}|samePass:${value}|password|min:9|max:20`;

/** 새 비밀번호 확인*/
export const PASSWORD_CHANGE_CONFIRM = (value) => `required|confirmed:${value}`;

// #region 대시보드 (prefix : DASHBOARD)

/** 새 대시보드 제목 */
export const DASHBOARD_NEW_TITLE_RULE = "required|min:1|max:30";

/** 대시보드 변경 */
export const DASHBOARD_MOTIFY_TITLE_RULE = "required|min:1|max:30";

/** 건물정보 사업자명 */
export const DASHBOARD_BIZ_OWNER_RULE = "max:30";

/** 건물정도 주소 */
export const DASHBOARD_BIZ_ADDR_RULE = "max:100";

/** 건물정보 면적 */
export const DASHBOARD_BIZ_AREA_RULE = "both-decimal:0,9|min:0|min_value:0|max:10|max_value:999999.99";

/** 건물정보 주요 생산품 */
export const DASHBOARD_BIZ_KEY_CONTENTS_RULE = "max:100";

/** 위젯 항목명 */
export const DASHBOARD_WIDGET_ITEM_NAME_RULE = "max:30";

/** 위젯 표시명 */
export const DASHBOARD_WIDGET_VIEW_NAME_RULE = "max:30";

/** 위젯 단위 */
export const DASHBOARD_WIDGET_UNIT_RULE = "max:30";

/** 위젯 소수점자리 */
export const DASHBOARD_WIDGET_DECIMAL_RULE = "required|min:1|min_value:1|max:2|max_value:99";

/** 위젯 후처리 */
export const DASHBOARD_WIDGET_POSTPROCESS_RULE = "max:500";

/** 위젯 베이스라인 시작일 */
export const DASHBOARD_WIDGET_BASELINE_START_RULE = "non_korean|max:30";

/** 위젯 베이스라인 종료일 */
export const DASHBOARD_WIDGET_BASELINE_END_RULE = "non_korean|max:30";

/** 위젯 비교라인 시작일 */
export const DASHBOARD_WIDGET_COMPARE_START_RULE = "non_korean|max:30";

/** 위젯 비교라인 종료일 */
export const DASHBOARD_WIDGET_COMPARE_END_RULE = "non_korean|max:30";

/** 위젯 폰트 크기 */
export const DASHBOARD_WIDGET_FONTSIZE_RULE = "required|min:1|min_value:1|max:2|max_value:99";

/** 위젯 최소값 */
export const DASHBOARD_WIDGET_MINVALUE_RULE = "both-decimal:0,5|min:0|min_value:-99999.99|max:9|max_value:99999.99";

/** 위젯 최대값 */
export const DASHBOARD_WIDGET_MAXVALUE_RULE = "both-decimal:0,5|min:0|min_value:-99999.99|max:9|max_value:99999.99";

// #region 데이터 수집 (prefix : COLLECT_DATA)

/** 조회 */
export const COLLECT_DATA_SEARCH_RULE = "max:30";

/** 데이터 수집기명 */
export const COLLECT_DATA_EQUIP_NAME_RULE = "required|min:1|max:15";

/** 통신채널 명칭 */
export const COLLECT_DATA_CHANNEL_NAME_RULE = "required|min:1|max:20";

/** Modbus-Rtu /rs485 com port */
export const COLLECT_DATA_MODBUS_RS_PORT_RULE = "required|min:1|max:3|min_value:1|max_value:255";

/** Modbus-TCP host */
export const COLLECT_DATA_MODBUS_HOST_RULE = "required|min:5|max:30";

/** Modbus-TCP port*/
export const COLLECT_DATA_MODBUS_PORT_RULE = "required|min:1|min_value:0|max:5|max_value:65535";

/** KIEMECS/ KevinLab/ Aquaman HOST */
export const COLLECT_DATA_KIEMECS_KL_AQUA_HOST_RULE = "required|min:5|max:30";

/** KIEMECS / KevinLab PORT */
export const COLLECT_DATA_KIEMECS_KL_PORT_RULE = "required|min:1|min_value:0|max:5|max_value:65535";

/** KIEMECS/ KevinLab/ Aquaman 인증 주소 */
export const COLLECT_DATA_KIEMECS_KL_AQUA_ADDR_RULE = "non_korean|max:50";

/** KIEMECS / KevinLab 인증 아이디 */
export const COLLECT_DATA_KIEMECS_KL_ID_RULE = "non_korean|max:32";

/**KIEMECS / KevinLab / Aquaman 인증 비밀번호 */
export const COLLECT_DATA_KIEMECS_KL_AQUA_PASSWORD_RULE = "non_korean|max:20";

/**KIEMECS / KevinLab / Aquaman API 공통 파라매터 */
export const COLLECT_DATA_KIEMECS_KL_AQUA_API_RULE = "non_korean|max:30";

/** KevinLab 암호키 */
export const COLLECT_DATA_KL_PASSKEY_RULE = "non_korean|max:50";

/** 계측기 ID */
export const COLLECT_DATA_MEASUING_ID_RULE = "required|non_korean|min:1|max:30";

/** 계측기명 */
export const COLLECT_DATA_MEASUING_NAME_RULE = "required|min:1|max:30";

/** 장비 고유번호 */
export const COLLECT_DATA_EQUIP_UNIQUE_NUM_RULE = "min:0|min_value:0|max:3|max_value:255";

/** 모듈명 */
export const COLLECT_DATA_MODULE_NAME_RULE = "required|min:1|max:30";

/** 모듈번호 */
export const COLLECT_DATA_MODULE_NUM_RULE = "required|min:1|min_value:1|max:3|max_value:999";

export const COLLECT_DATA_PULSE_UNIT_RULE = "required|min:1|min_value:0|max:6|max_value:999999";


/** 데이터매핑키 */
export const COLLECT_DATA_MAPPING_KEY_RULE = "max:40";

// #region 관제점 (prefix : POINT)

/** 관제점 주소 */
export const POINT_ADDR_RULE = "required|non_korean|min:1|max:30";

/** 관제점 명 */
export const POINT_NAME_RULE = "required|min:1|max:50";

/** 에너지 공급유형 */
export const POINT_ENERGY_ITEM_RULE = "max:100";

/** 필터링 최소값 */
export const POINT_SET_MIN_RULE = "both-decimal:0,16|min:0|min_value:-999999999999.99|max:17|max_value:999999999999.99";
/** 필터링 최대값 */
export const POINT_SET_MAX_RULE = "both-decimal:0,16|min:0|min_value:-999999999999.99|max:16|max_value:999999999999.99";
/** 고정값 */
export const POINT_SET_FIXED_RULE = "both-decimal:0,16|min:0|min_value:-999999999999.99|max:16|max_value:999999999999.99";

// #region 관제점 계산식 (prefix : POINT_FORMULA)

/** 워크플로우 명 */
export const POINT_FORMULA_WORKFLOW_NAME_RULE = "required|min:1|max:30";

/** 워크플로우 설명 */
export const POINT_FORMULA_WORKFLOW_DETAIL_RULE = "max:50";

/** 계산식 그룹 추가/수정 > 그룹명 */
export const POINT_FORMULA_GROUP_NAME_RULE = "required|min:1|max:30"; // 사용되고 있지 않음, 변경 사항은 적용

/** 계산식 그룹 추가/수정 > 로직 /계획/연산 */
export const POINT_FORMULA_GROUP_LOGIC_PLAN_CALC_RULE = "max:500";

/** 계산식 추가/수정 > 계산식명 */
export const POINT_FORMULA_NAME_RULE = "required|min:1|max:30";

/** 계산식 추가/수정 > 로직/계획/연산 */
export const POINT_FORMULA_ADD_LOGIC_PLAN_CALC_RULE = "max:500";

// #region 사용자 정의 관제점 (prefix : CUSTOM_POINT)

/** 사용자 정의 관제점 > 월 입력값 */
export const CUSTOM_POINT_MONTH_VALUE_RULE = "required|both-decimal:1,12|min:1|min_value:-999999999999.99|max:16|max_value:999999999999.99";
export const CUSTOM_POINT_EXCEPTION_VALUE_RULE = "required|integer-decimal:1,12|min:1|min_value:0|max:16|max_value:999999999999.99";

// #region 구역 (prefix : ZONE)

/** 구역명 */
export const ZONE_NAME_RULE = "required|min:1|max:30";

/** 구역 상세 */
export const ZONE_DETAIL_RULE = "max:50";

// #region 설비 (prefix : EQUIP)

/** 조회설비명 */
export const EQUIP_SEARCH_NAME_RULE = "max:30"; // 사용되고 있지 않음, 변경 사항은 적용

/** 추가설비명 */
export const EQUIP_ADD_NAME_RULE = "required|min:1|max:50";

/** 설비 X좌표 */
export const EQUIP_POSITION_X_RULE = "required|min:0|min_value:0|max:4|max_value:9999";

/** 설비 Y좌표 */
export const EQUIP_POSITION_Y_RULE = "required|min:0|min_value:0|max:4|max_value:9999";

/** 기타항목 */
export const EQUIP_ETCINFO_RULE = "max:100";

/** 설비 그룹 명 */
export const EQUIP_GROUP_NAME_RULE = "required|min:1|max:30";

/** 설비 관제점 명 */
export const EQUIP_POINT_NAME_RULE = "max:30"; // 사용되고 있지 않음, 변경 사항은 적용

/** 설비 관제점 상세 */
export const EQUIP_POINT_DETAIL_RULE = "max:50";

// #region 요금 (prefix : COST)

/** 계약전력 */
export const COST_CONTRACT_ELEC = "required|both-decimal:1,4|min:1|min_value:0|max:6|max_value:9999.99";
/** 요금표 명칭 */
export const COST_CONTRACT_NAME = "required|min:1|max:30";

/** 기본 요금 */
export const COST_BASIC_COST = "required|min:1|min_value:0|max:12|max_value:999999999999";

/** 기본 부하 */
export const COST_ORIGIN_LANG = "required|both-decimal:1,4|min:1|min_value:0|max:6|max_value:9999.99";

// #region 검교정 (prefix : CLBRN) ================================================================================

/** 검교정 검색어 */
export const CLBRN_SEARCH_RULE = "max:30";

/** 검교정 내역 */
export const CLBRN_HISTORY_RULE = "required|min:1|max:100";

/** 검교정 담당자 */
export const CLBRN_MANAGER_RULE = "required|min:1|max:20";

/** 검교정 기관 */
export const CLBRN_COMPANY_RULE = "max:30";

/** 비고 */
export const CLBRN_ETC_RULE = "max:500";

// #region 휴일 (prefix : HOLIDAY)

/** 휴일명 */
export const HOLIDAY_NAME_RULE = "required|min:1|max:30";

/** 비고 */
export const HOLIDAY_ETC_RULE = "max:100";

// #region 공통 대분류 (prefix : COMMON_LARGE_CODE)

/** 공통 대분류 조회 코드명 */
export const COMMON_LARGE_CODE_SEARCH_NAME_RULE = "max:30";

/** 공통 대분류 코드 */
export const COMMON_LARGE_CODE_RULE = "required|non_special_num|min:1|max:20";

/** 공통 대분류 코드명 */
export const COMMON_LARGE_CODE_NAME_RULE = "required|min:1|max:30";

/** 공통 대분류 정렬순서 */
export const COMMON_LARGE_CODE_SORT_RULE = "required|min:1|min_value:0|max:4|max_value:9999";
/** 공통 대분류 기타정보 */
export const COMMON_LARGE_CODE_ETCINFO_RULE = "max:500";
/** 공통 대분류 비고 */
export const COMMON_LARGE_CODE_ETC_RULE = "max:500";

// #region 공통 소분류 (prefix : COMMON_SMALL_CODE)

/** 공통 소분류 코드 */
export const COMMON_SMALL_CODE_RULE = "required|non_special_num|min:1|max:30";
/** 공통 소분류 코드명 */
export const COMMON_SMALL_CODE_NAME_RULE = "required|min:1|max:30";

/** 공통 소분류 정렬순서 */
export const COMMON_SMALL_CODE_SORT_RULE = "required|min:1|min_value:0|max:4|max_value:9999";
/** 공통 소분류 기타정보 */
export const COMMON_SMALL_CODE_ETCINFO_RULE = "max:500";

/** 공통 소분류 비고 */
export const COMMON_SMALL_CODE_ETC_RULE = "max:500";

// #region 계측기 유형 (prefix : EQUIP_TYPE_CODE)

/** 계측기 조회 유형명 */
export const EQUIP_TYPE_CODE_SEARCH_NAME_RULE = "max:30";

/** 계측기 유형 코드 */
export const EQUIP_TYPE_CODE_RULE = "required|non_special_num|min:1|max:10";

/** 계측기 유형명 */
export const EQUIP_TYPE_CODE_NAME_RULE = "required|min:1|max:30";

/** 계측기 유형 설명 */
export const EQUIP_TYPE_CODE_DETAIL_RULE = "max:100";

// #region 단위 대분류 (prefix : UNIT_LARGE_CODE)

/** 단위 대분류 조회 코드명 */
export const UNIT_LARGE_CODE_SEARCH_NAME_RULE = "max:30"; // 사용되고 있지 않음, 변경 사항은 적용

/** 단위 대분류 코드 */
export const UNIT_LARGE_CODE_RULE = "required|non_special_num|min:1|max:10";

/** 단위 대분류 코드명 */
export const UNIT_LARGE_CODE_NAME_RULE = "required|min:1|max:30";

/** 단위 대분류 코드 상세 */
export const UNIT_LARGE_CODE_DETAIL_RULE = "max:100";

// #region 단위 소분류 (prefix : UNIT_SMALL_CODE)

/** 단위 소분류 코드 */
export const UNIT_SMALL_CODE_RULE = "required|non_special_num|min:1|max:10";

/** 단위 소분류 코드명 */
export const UNIT_SMALL_CODE_NAME_RULE = "required|min:1|max:20";

/** 단위 소분류 코드 상세 */
export const UNIT_SMALL_CODE_DETAIL_RULE = "max:100";

// #region 지역 코드 (prefix : REGION_CODE)

/** 지역 코드 조회 */
export const REGION_CODE_SEARCH_RULE = "max:30";

/** 지역 코드 */
export const REGION_CODE_RULE = "required|non_korean|min:1|max:10";

/** 지역 코드명 */
export const REGION_CODE_NAME_RULE = "required|min:1|max:30";

// #region 대시보드 관리 (prefix : DASHBOARD_MANAGE)

/** 대시보드 제목 */
export const DASHBOARD_MANAGE_CODE_RULE = "required|min:1|max:30"; // 사용되고 있지 않음, 변경 사항은 적용

/** 대시보드 다른 이른 저장 */
export const DASHBOARD_MANAGE_NEW_NAME_RULE = "required|min:1|max:30";

/** 대시보드 분석 템플릿 저장 */
export const DASHBOARD_MANAGE_TEMPLATE_RULE = "required|min:1|max:30";

// #region 권한 관리 메뉴 (prefix : MENU)

/** 메뉴 명 */
export const MENU_NAME_RULE = "required|min:1|max:30";

/** 리소스 유형 URL 경로*/
export const MENU_RESOURCE_URL_RULE = "max:100";

/** 표시순서 */
export const MENU_VIEW_ORDER_RULE = "required|min_value:0|max_value:9999|min:1|max:4";

/** 다국어 */
export const MENU_LANG_RULE = "max:30";

/** 그룹명 */
export const MENU_GROUP_NAME_RULE = "required|min:1|max:30";

/** 사용자 아이디 */
export const MENU_USER_ID_RULE = "required|check_reserved_id|non_korean|min:5|max:30";

/** 비밀번호 */
export const MENU_PASSWORD_RULE = (id) => `required|password|confirmPass:${id}|min:9|max:20`;

/** 사용자명 */
export const MENU_USER_NAME_RULE = "required|non_special|min:1|max:30";

/** 이메일 */
export const MENU_EMAIL_RULE = "required|min:1|max:30";

/** 전화번호 */
export const MENU_PHONE_RULE = "max:30|phone";

// #region 시스템 로그 (prefix : LOG)

/** 검색어 */
export const LOG_SEARCH_RULE = "max:30";

/** 요약정보 */
export const LOG_SIMPLE_INFO_RULE = "max:2000";

/** 추적메세지 */
export const LOG_TARGET_MESSAGE_RULE = "max:10000";

// #region 설비 계통 및 유형 (prefix : EQUIP_SYSTEM)

/** 설비 조회계통명 */
export const EQUIP_SYSTEM_SEARCH_NAME_RULE = "max:30";

/** 설비 계통 코드 */
export const EQUIP_SYSTEM_CODE_RULE = "required|non_korean|min:1|max:10";

/** 설비 계통명 */
export const EQUIP_SYSTEM_NAME_RULE = "required|min:1|max:30";

/** 설비 계통 설명 */
export const EQUIP_SYSTEM_DETAIL_RULE = "max:100";

/** 설비 조회 유형명 */
export const EQUIP_SYSTEM_TYPE_SEARCH_NAME_RULE = "max:30";

/** 설비 유형 코드 */
export const EQUIP_SYSTEM_TYPE_CODE_RULE = "required|non_korean|min:1|max:10";

/** 설비 유형 명 */
export const EQUIP_SYSTEM_TYPE_NAME_RULE = "required|min:1|max:30";

/** 설비 유형 설명 */
export const EQUIP_SYSTEM_TYPE_DETAIL_RULE = "max:100";

/** 설비 수집 항목 코드 */
export const EQUIP_SYSTEM_COLLECT_CODE_RULE = "required|non_special|non_korean|min:1|max:30";

/** 설비 수집 항목 명*/
export const EQUIP_SYSTEM_COLLECT_NAME_RULE = "required|min:1|max:30";
/** 설비 수집 제어옵션 */
export const EQUIP_SYSTEM_COLLECT_CTRL_OPTION_RULE = "required";

/** 설비 수집 사용여부 */
export const EQUIP_SYSTEM_COLLECT_IS_USE_RULE = "required";

/** 설비 수집 항목 정렬 순서 */
export const EQUIP_SYSTEM_COLLECT_SORT_RULE = "required|min:1|min_value:0|max:4|max_value:9999";
/** 설비 수집 항목 비고 */
export const EQUIP_SYSTEM_COLLECT_ETC_RULE = "max:100";

/** 설비 제어 항목명 */
export const EQUIP_SYSTEM_CONTROL_NAME_RULE = "required|min:1|max:30";

// #region 알람 (prefix : ALARM)


/** 알람 기준 검색어 */
export const ALARM_BASE_SEARCH_NAME_RULE = "max:30";

/** 알람 그룹 */
export const ALARM_BASE_GROUP_RULE = "required|min:1|max:30";

/** 알람 LL */
export const ALARM_BASE_VALUE_L_RULE = "required|both-decimal:1,12|min:1|max:16";

/** 알람 L */
export const ALARM_BASE_VALUE_LL_RULE = "both-decimal:1,12|min:1|max:16";

/** 알람 H */
export const ALARM_BASE_VALUE_H_RULE = "required|both-decimal:1,12|min:1|max:16";

/** 알람 HH */
export const ALARM_BASE_VALUE_HH_RULE = "both-decimal:1,12|min:1|max:16";

/** 알람 경고메세지 */
export const ALARM_BASE_WARN_MESSAGE_RULE = "max:500";

/** 알람 해결방안 */
export const ALARM_BASE_SOLVED_RULE = "max:500";

/** 알람 테스트 값 */
export const ALARM_BASE_TEST_VALUE_RULE = "both-decimal:0,12|min:1|max:16";

/** 알람 발생이력 검색어 */
export const ALARM_ENABLE_SEARCH_RULE = "max:30";

/** 서브 모듈명 */
export const SUB_MODULE_NAME_VALID_RULE = "required|non_special_num|max:8";

/** 모듈 표시명 */

export const MODULE_ALIAS_VALID_RULE = "required|non_slash|max:20"