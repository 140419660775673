
<template>
 <!-- ^트러블슈팅. vue-codemirror props 직접접근을 통한 데이터변경 에러. vue-codemirror와 codemirror간 이벤트 리스너가 차이가 났다. 라이브러리 선정 및 버전(module not found) 이슈.  -->
  <div ref="codeMirrorContainer" class="code-mirror-container"></div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "codemirror/addon/merge/merge.js";
import "codemirror/addon/merge/merge.css";
import "codemirror/theme/neo.css";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import CodeMirror from "codemirror";
import { mapState } from "vuex";
import xelib from "xelib";
import 'codemirror/mode/javascript/javascript';


export default {
  /**
   *  발표 순서
   * 
   * 1. 프로젝트 개요
   *    1). 프로젝트의 목적(기존 버전)
   *        - textarea의 크기가 필요 이상으로 가변적 => UI 관점 상 레이아웃 붕괴, 디자인 흐름에서 벗어남
   *        - 사용자가 모든 관제 점을 기억해야 하는 문제
   *        - 물리 관제 점과 연산자, 예약어 등이 구분되지 않아 가독성 저하
   * 
   *    2). 기능 요약
   *        - 컴포넌트 크기 고정. 스크롤을 활용한 데이터 표출
   *        - 관제 점 공식에 대한 사용자의 기억 일부만으로 검색 기능
   *        - 물리 관제 점, 자바스크립트 예약어에 대한 코드 하이라이팅 기능을 통한 가독성 향상
   * 
   *    3). 개발 도중 요구사항
   *        - 자동완성 컴포넌트 디자인 수정 요구. codemirror의 자체적인 기능에 의해 의도치 않은 숫자 하이라이팅 방지(사진 자료)
   *        - 페이지 위치와 상관없이 어느 페이지에서든 다른 페이지의 관제 점 위치를 참조할 수 있도록
   *        - 자동완성 컴포넌트 내 물리관제점에 대한 설명 추가 후 설명을 통한 검색도 가능하도록
   *        - UPL객체 내의 함수도 자동완성이 가능했으면 좋겠다.
   *        - [ ] 내부만 하이라이팅이 됐으면 좋겠다.
   *        - 기존에는 컨트롤 스페이스 방식의 자동완성 컴포넌트 렌더링 방식에서 입력 이벤트 기반 동작으로 수정되었으면 좋겠다.
   *        - return과 같은 자바스크립트 예약어에도 하이라이팅이 들어왔으면 좋겠다.
   *         
   * 2. 데모 시연 및 대략적인 설명
   * 
   * 3. 코드 구현 설명 및 트러블슈팅 경험(라이프사이클 순서대로)
   * 
   * 4. 남은 도전과제와 느낀 점
   *        - 함수에도 하이라이팅이 들어가는 기능요구 => 함수명을 잡아내면서도 확장성 있는 정규표현식을 고민하고 있다. 
   *        - git 충돌 시 트러블슈팅 능력 배양이 필요하다고 느꼈다.
            - 데이터를 보여주는 기능은 구현하였지만 실제 데이터를 api를 활용해서 반영하고 다시 fectching하는 테스트가 남아있다.
   *        - vue.js에서 문제가 발생할 시 라이프사이클 순서대로 해결점을 찾아가면 간편할 수 있다고 생각
   *        - 라이브러리를 선택할 때 라이브러리 종류도 중요하지만 사용할 버전의 중요성 또한 느낌
   *        - 기능 확장에 대한 조언을 통해 명확한 방향성을 수립하게 되었다.
   */
  props: ["value","disabled","hintList","arrName","existExplanation"],
  data() {
    return {
      codeMirrorInstance: null,

 /**
 * 코드 에디터의 옵션 설정 객체입니다.
 * 이 객체는 코드 에디터의 동작 방식을 설정하는 데 사용됩니다.
 *
 * @typedef {object} EditorOptions
 * @property {number} tabSize - 탭의 크기(스페이스 수).
 * @property {string} mode - 에디터의 모드 설정. 예: "plain/text",javascript.
 * @property {boolean} lineNumbers - 라인 번호를 표시할지 여부.
 * @property {boolean} lineWrapping - 긴 줄의 줄바꿈 여부.
 * @property {string} theme - 에디터의 테마 설정. 예: "neo".
 * @property {object} hintOptions - 자동완성 힌트 관련 옵션.
 * @property {Function} hintOptions.hint - 입력 처리 함수. `this`는 컴포넌트를 가리킵니다.
 * @property {boolean} hintOptions.completeSingle - 자동완성 힌트가 단일 항목으로 완성되는지 여부.
 */

/** @type {EditorOptions} */
      options: {
        tabSize: 4,
        // mode: "plain/text",
        mode:"javascript",
        lineNumbers: true,
        lineWrapping: true,
        readOnly:this.disabled?"nocursor":false,
        theme: "neo",
        hintOptions: {
          hint: this.handleInput,
          completeSingle: false,
        },
        style:{backgroundColor:{type:String,default:'#ffffff'}}
      },

      /**
       * 자동완성 리스트입니다.
       * @type {string[]} 
       */
      hintLists: null,
    };
  },
   created() {
    if(this.hintList){
      this.hintLists=this.hintList;
    }
    // this.processData();
    // this.totalHint();
  },
  mounted() {
    this.initializeCodeMirror(); 
  },
  beforeDestroy() {
    /** 컴포넌트 destroy 시 이벤트 해제 (사진자료) */
    if (this.codeMirrorInstance) {
      this.codeMirrorInstance.toTextArea();
      this.codeMirrorInstance = null;
    }
  },
  computed: {
    ...mapState({
      pointList: (state) => state.pointList,
    }),
  },
  watch: {

    /**
     * 페이지가 변경될 시 새로운 데이터와 기존의 데이터를 비교하여 갱신.
     * @param {string} newVal props로 새로운 데이터를 받는다.
     */
    value(newVal) {
      if (
        this.codeMirrorInstance &&
        this.codeMirrorInstance.getValue() !== newVal
      ) {
        this.codeMirrorInstance.setValue(newVal);
      }
    },
  },
  methods: {

    /**
     * 자동완성 text를 공백을 기준으로 나눠 자동완성에 보여줄 내용과 실제로 입력될 내용을 분리하기위함
     * @param {string} item 공백을 기준으로 나눌 문자열
     * @returns {string} 나눈 배열에서 첫번째 토큰을 반환
     */
    inputHint(item){
      if(this.existExplanation){
                return  /UPL.\w+/.test(item.split(/\s/)[0])?item.split(/\s/)[0]:`${this.arrName}[${item.split(/\s/)[0]}]`;

      }
      else return /UPL.\w+/.test(item.split(/\s/)[0])?item:`${this.arrName}[${item}]`;
    },
    
    /**
     * UPL 함수들을 자동완성 목록에 포함시키기 위한 기능을 수행합니다.
     */
    totalHint() {

      /** @type {string[]} xelib UPL 지원 함수들에서 함수 이름만 추출하여 배열로 만듭니다. */
      const xelibArr = Object.keys(xelib.UplSupportFunctions);

      /** @type {string[]} UPL 전용 자동완성을 위해 각 함수 이름 앞에 'UPL.'을 추가합니다. */
      const xeliArrAddUpl = xelibArr?.map((item) => {
        return "UPL." + item;
      });

      // 기존의 힌트 목록에 UPL로 시작하는 함수 이름들을 병합합니다.
      this.hintLists = [...this.hintLists, ...xeliArrAddUpl];
      return;
    },

    /**
     * '물리관제점 (물리관제점 설명)'의 형식으로 자동완성 컴포넌트 표출. 
     * @returns {void}
     */
    processData() {
      this.hintLists = this.pointList.map((item) => {
        return `${item.ptAddr} (${item.ptName})`
      });
    },
    
    /**
     * ^트러블슈팅. 기존의 브라켓을 인지하는 알고리즘 => 정규표현식. [ ]내부의 내용을 하이라이팅 시키기 위함 
     */ 
    highlightBrackets() {
      const cm = this.codeMirrorInstance;
      const doc = cm.getDoc();
      const text = doc.getValue();

      /**@type {string} [ ]내부를 하이라이팅하기위한 정규표현식 */
      const regexBigBraket = /\[([^\]]+)\]/g;
      let match;

      while ((match = regexBigBraket.exec(text)) !== null) {
        const start = cm.posFromIndex(match.index + 1);
        const end = cm.posFromIndex(match.index + match[0].length - 1);
        cm.markText(start, end, { className: "highlight" });

      }
    },
    
    /** UPL함수토큰을 하이라이팅 시키는 함수 */
    highlightUPLfunc(){
      const cm=this.codeMirrorInstance;
      const doc=cm.getDoc();
      const text=doc.getValue();

      const regex = /UPL\.\w+/g;
      let match;
      for(let i=0;(match=regex.exec(text))!==null;i++){
       const start = cm.posFromIndex(match.index);
        const end = cm.posFromIndex(match.index + match[0].length);
        cm.markText(start, end, { className: "highlightUPL" });
     }
  
    }
,
    /** 
     * 마운트 시 코드미러를 초기화 시키기 위함
    */
    initializeCodeMirror() {
     
      this.codeMirrorInstance = CodeMirror(this.$refs.codeMirrorContainer, {
        value: this.value||"",
        ...this.options,
      });
      
     setTimeout(()=>{
     this.codeMirrorInstance.refresh();
     },10)
      
      this.codeMirrorInstance.on("inputRead", (cm,change) => {

    /** ^트러블슈팅. 의도치 않은 자동완성 컴포넌트 렌더링 이슈*/
        if (change.text[0] && /[a-zA-Z0-9.가-힣]/.test(change.text[0])) {
          cm.showHint();
        }
      });

      this.codeMirrorInstance.on("changes", () => {
        this.$emit("input", this.codeMirrorInstance.getValue());
        this.highlightBrackets();
        this.highlightUPLfunc();
      });

      this.highlightBrackets();
      this.highlightUPLfunc();
    },
    
    /** 
     * 자동완성 목록을 커스텀 하기위한 함수
     * @param {{editor:object}} editor codemirror editor의 객체
     * 
    */
    handleInput(editor) {
      editor.showHint({
        hint: this.customHint.bind(this),
      });
    },

    /**
     * 자동완성 목록을 커스텀 하기위한 로직이 있는 함수
     * @param {{editor:object}} editor - codemirror editor의 객체
     * @returns {object} 자동완성 라스트,자동완성 후 덮어쓰기 정보
     */
    customHint(editor) {

      /**@type {Pos:object} - 커서의 위치에 대한 객체. 현재의 라인, 현재의 라인에서 커서의 위치에대한 정보*/
      const cursor = editor.getCursor();

      /**@type {string} 코드미러에서 현재 라인의 문자열  */
      const stringPerLine = editor.getLine(cursor.line);
     
     /**@type {number} 초기화는 현재커서의 위치이며 추후 반복문을 통해 앞으로 이동하며 시작인덱스로 재할당 예정이기에 let */
      let start = cursor.ch;

      /**@type {number} 자동완성하여 덮어쓸 위치의 끝. 재할당이 되지않기에 const*/
      const end = cursor.ch;

      /**@type {string} 사용자가 입력한 문자열*/
      let word = "";
      
       /** 입력한 문자에서 공백과 [이 아니면 앞으로 이동하며 start위치 조정 */
      while (start > 0 && /[^([\s]/.test(stringPerLine.charAt(start - 1))) {
        start--;
        word = stringPerLine.charAt(start) + word;
      }
  
     /** 자동완성 전체 목록중에 사용자가 입력한 내용을 바탕으로 필터링하기 위함 */
      const list = this.hintLists.filter((item) => item.includes(word));

      if (list.length === 0) {
        return null;
      }

      return {
        list: list.map(item=>({displayText:item,text:this.inputHint(item)})),
        from: CodeMirror.Pos(cursor.line, start),
        to: CodeMirror.Pos(cursor.line, end), 
      };
    },
  },
};
</script>

<style scoped>


</style>
