/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
	<div class="GridDetailBox">
		<div class="row row-cols-5 mb-3">
			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('관제점 유형')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
				<select class="form-control" :ref="'isVirtual'" v-model="model.isVirtual" @change="getPointGroupList($event.target.value)">
					<option v-for="(data, index) in isVirtualList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>

			<div id="datalist" class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('관제점 그룹')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
				<select class="form-control" :ref="'ptGroup'" v-model="model.ptGroup" @input="openPointGroupModal($event.target.value)">
					<option v-for="(data, index) in searchPointGroup" :key="index" :value="data.groupCode">
						{{ data.groupCodeName }}
					</option>
				</select>
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('관제점 주소')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
				<valid-input
        :vid="'관제점 주소'"
					:inputType="'text'"
					:forceErrorHandle="forceErrorHandle"
					:inputValue.sync="model.ptAddr"
					:rules="rules.POINT_ADDR_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
					@changeMethod="ptAddrDuplCheck"
				>
				</valid-input>

				<!-- <input :ref="'ptAddr'" class="form-control" v-model="model.ptAddr" :disabled="IsNEW == true ? false : true"/> -->
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('관제점명')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
				<valid-input :vid="'관제점명'" :inputType="'text'" :inputValue.sync="model.ptName" :rules="rules.POINT_NAME_RULE" :errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 50)"> </valid-input>

				<!-- <input :ref="'ptName'" class="form-control" v-model="model.ptName"/> -->
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('집계 유형')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i>
				<select class="form-control" :ref="'ptTypeCode'" v-model="model.ptTypeCode">
					<option v-for="(data, index) in ptTypeCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>
		</div>

		<div class="row row-cols-5 mb-3">
			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('에너지원')}}</span>
				<select class="form-control" :ref="'energySourceCode'" v-model="model.energySourceCode">
					<option value="">{{$t('선택')}}</option>
					<option v-for="(data, index) in energySourceCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('적용 구역')}}</span>
				<!-- <tree-select :load-options="loadOptions"
            v-model="model.applyZoneIdx" :multiple="false" :options="options" @select="onZoneSelected" :placeholder="$t('구역선택')"
            /> -->
				<select class="form-control" :ref="'zoneDvsnCode'" v-model="model.applyZoneIdx">
					<option v-for="(data, index) in zoneInfoListOption" :key="index" :value="data.zoneIdx">
						{{ data.zoneName }}
					</option>
				</select>
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('관제점 용도')}}</span>
				<select class="form-control" :ref="'itemUseageCode'" v-model="model.itemUseageCode" :disabled="model.isVirtual === ''">
					<option value="">{{$t('선택')}}</option>
					<option v-for="(data, index) in selectPointUsageList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>

			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('에너지 생산 유형')}}</span>
				<select class="form-control" :ref="'renewTypeCode'" v-model="model.renewTypeCode">
					<option value="">{{$t('선택')}}</option>
					<option v-for="(data, index) in renewTypeCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>

			<div class="col text-left contenfs-col">
				<span class="h5 mb-0">{{$t('설비기기 유형')}}</span>
				<select class="form-control" :ref="'renewTypeCode'" v-model="model.equipTypeCode">
					<option value="">선택</option>
					<option v-for="(data, index) in equipTypeList" :key="index" :value="data.equipTypeCode">
						{{ data.equipTypeCodeName }}
					</option>
				</select>
			</div>
		</div>

		<div class="row row-cols-5">
			<div class="col text-left contens-col">
				<span class="h5 mb-0">{{$t('에너지 공급 유형')}}</span>

				<select class="form-control" :ref="'energySplyTypeCode'" v-model="model.energySplyTypeCode">
					<option value="">{{$t('선택')}}</option>
					<option v-for="(data, index) in energySplyTypeCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>

			<!-- <div class="col text-left contens-col">
          <span class="h5 mb-0">원단위 구분</span>
          <select class="form-control" :ref="'orgUnitDvsnCode'" v-model="model.orgUnitDvsnCode">
            <option value="">선택</option>
            <option v-for="(data, index) in orgUnitDvsnCodeList" :key="index" :value="data.smallCode">
              {{ data.smallCodeName }}
            </option>
          </select>
        </div>
        
        <div class="col text-left contens-col">
          <span class="h5 mb-0">관제점 속성</span>
          <select class="form-control" :ref="'itemPropCode'" v-model="model.itemPropCode">
            <option value="">선택</option>
            <option v-for="(data, index) in itemPropCodeList" :key="index" :value="data.smallCode">
              {{ data.smallCodeName }}
            </option>
          </select>
        </div> -->

			<div class="col text-left"></div>
			<div class="col text-left"></div>
			<div class="col text-left"></div>
			<div class="col text-left"></div>
		</div>

		<b-modal ref="pointGroupModal" :title="$t('관제점 그룹 선택')">
			<SelectPointGroupModal :pointGroupCode="model.ptGroup" @hideModal="hidePointGroupModal"></SelectPointGroupModal>
			<template #modal-footer>
				<b-button class="btn-danger" @click="$refs.pointGroupModal.hide()">{{$t('닫기')}}</b-button>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
	.form-control-name {
		width: 100%;
		height: calc(1.5em + 14px + 2px);
		padding: 7px 0.75rem;
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 1.5;
		color: #2d353c;
		background-color: #fff;
	}

	.GridDetailBox .contens-col i {
		vertical-align: text-bottom;
	}

	#onGroupList {
		background-color: red;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xelib from "xelib";
	import SelectPointGroupModal from "@src/views/pointManagement/point/modal/SelectPointGroupModal.vue";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";
	import { StatusCodes } from "http-status-codes";

	const { Unit } = xelib.PointBox;

	const unitOptions = (function() {
		return Object.keys(Unit.types).map((type, i) => {
			let children = Object.keys(Unit.types[type]).map((v) => {
				// return { id: i}-${j, label: v };
				return { id: v, label: v };
			});

			return { id: i, label: type, children: children };
		});
	})();

	export default {
		components: {
			SelectPointGroupModal,
		},
		props: ["data", "IsNEW"],
		watch: {

    },
		data() {
			return {
				forceErrorHandle: false,
				publicCodeList: null,
				unitOptions: unitOptions,
				newPoint: {},
				searchPointGroup: null,
				isVirtualList: null,
				ptGroupSmallCodeList: null,
				ptTypeCodeList: null,
				valueTypeCodeList: null,
				purpsUseCodeList: null,
				dataCreateCodeList: null,
				dataSourceCodeList: null,
				itemUseageCodeList: null,
				itemPropCodeList: null,
				energySourceCodeList: null,
				trgthingCodeList: null,
				renewTypeCodeList: null,
				orgUnitDvsnCodeList: null,
				instmnPeriodCodeList: null,
				agrgtnTargetCodeList: null,
				unitLargeCodeList: null,
				unitSmallCodeList: null,
				covUnitLargeCodeList: null,
				covUnitSmallCodeList: null,
				zoneInfoListOption: null,
				energySplyTypeCodeList: null,
				isCovList: null,
				isIgnoreZeroList: null,
				equipTypeList: null,
				valCheckCategory: [
					{ key: "isVirtual", name: "관제점 유형" },
					{ key: "ptTypeCode", name: "집계 유형" },
					{ key: "ptGroup", name: "관제점 그룹" },
					{ key: "ptAddr", name: "관제점 주소" },
					{ key: "ptName", name: "관제점명" },
				],

				model: {
					ptIdx: null,
					ptAddr: "",
					ptName: "",
					ptTypeCode: "",
					isVirtual: "",
					isVirtualName: "",
					ptGroup: "",
					ptGroupName: "",
					energySourceCode: "",
					energySourceName: "",
					applyZoneIdx: null,
					zoneName: "",
					itemUseageCode: "",
					itemUsageName: null,
					enabled: "",
					enabledName: "",
					energySplyTypeCode: "",
				},

				// define the default value
				applyZoneIdx: null,
				// define options
				options: null,
				rules,
				validMessage,
			};
		},
		computed: {
			selectPointUsageList() {
				if (this.model.isVirtual === 0 || this.model.isVirtual === "0") {
					return this.phscPointUsageList;
				} else if (this.model.isVirtual === 1 || this.model.isVirtual === "1") {
					return this.locPointUsageList;
				}
				return [];
			},
		},
		async created() {
			console.log(this.data);
			// await this.getSearchPointGroup();
			await this.getPointGroupList();
			await this.getCommonCodeList();
			await this.getZoneListHierarchi();
			await this.getUnitLargeCodeList();
			await this.getEquipTypeList();
			await this.getUnitSmallCodeList(this.data.unitLargeCode);
			await this.getCovUnitSmallCodeList(this.data.covUnitLargeCode);

			await this.initSetting();
		},
		mounted() {},
		methods: {
			changePtGroup(e) {
				this.model.ptGroup = e.target.value;
			},
			async initSetting() {
				this.$store.dispatch("UPDATE_PT_MAP");

				this.newPoint = { ptGroup: "", max: 999999999999.99, min: -999999999999.99, isVirtual: false, ptType: "LST", valueType: "analog", overflow: 0, isCov: true };

				if (this.isUpdate) {
					// 이전 리비전과 소스 에서 아래와 같이 변경함.
					/*           
					↓↓↓↓↓
				*/
					try {
						const result = await backEndApi.pointInfo.getPoint(this.ptAddr);
						const data = result.data;

						if (this.$err(data)) {
							this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
							return;
						}

						this.newPoint = data;

						// undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
						if (this.isEmpty(this.newPoint.unit)) this.newPoint.unit = null;
						if (this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;
					} catch (err) {
						new AppErrorHandler(err).printErrorLog().errHandling();
					}
				}
			},
			openPointGroupModal(ptGroup) {

				this.model.ptGroup = ptGroup;

        let pointGroupByPublicCode = this.publicCodeList.find(v => v.largeCode === 'pointGroup');

        let isLogical = pointGroupByPublicCode.smallCodeList.findIndex(v => v.smallCode === ptGroup);


				if (isLogical !== -1) {
					this.$refs.pointGroupModal.show();
				}
			},
			hidePointGroupModal({ ptName, ptAddr }) {
				console.log(ptName, ptAddr);
				this.model.ptAddr = ptAddr;
				this.model.ptName = ptName;
				this.$refs.pointGroupModal.hide();
			},
			setModel() {
				// if(this.IsNEW === true){
				//   this.model = this.data;
				//   return;
				// }

				// console.log(result);
				this.model = this.data;
			},
			async getPointGroupList(isVirtual) {
        this.model.ptGroup = "";
        this.model.ptAddr = "";
        this.model.ptName = "";

				let paramValue = "all";
				if (Number(isVirtual) === 0) {
					paramValue = "physical";
				} else if (Number(isVirtual) === 1) {
					paramValue = "logical";
				}

				let result = await backEndApi.pointInfo.getPointGroupList(paramValue);
				this.searchPointGroup = result.data;
			},

			async getEquipTypeList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.equipType.getEquipTypeList();
					this.equipTypeList = result.data;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async getSearchPointGroup() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.pointInfo.getPointGroupDetailList();
					this.searchPointGroup = result.data;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async getCommonCodeList() {
				const result = await backEndApi.codes.getCommonCodeList();
				const data = result.data;
				this.publicCodeList = data.publicCode;
				const publicCodes = this.publicCodeList;

				console.log(publicCodes);

				this.smallCodeByPoint = data;

				// 에너지 공급유형 코드
				// this.energySplyTypeCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "energySplyTypeCode"));
				// console.log(this.energySplyTypeCodeList);
				this.energySplyTypeCodeList = await backEndApi.publicCode.getOprtnSmallCodeList("energySplyTypeCode");
				this.energySplyTypeCodeList = this.energySplyTypeCodeList.data;

				// 관제점 유형 select box
				this.isVirtualList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "isVirtual"));

				// 집계 유형 select box
				this.ptTypeCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "ptTypeCode"));

				// 계측값 타입 select box
				this.valueTypeCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "valueTypeCode"));

				// 활용목적 select box
				this.purpsUseCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "purpsUseCode"));

				// 자료생성 select box
				this.dataCreateCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "dataCreateCode"));

				// 자료획득원 select box
				this.dataSourceCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "dataSourceCode"));

				// 관제점 용도 select box
				this.itemUseageCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "itemUseageCode"));

				// 관제점 속성 select box
				this.itemPropCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "itemPropCode"));

				// 에너지원 select box
				this.energySourceCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "energySourceCode"));

				// 대상물질 select box
				this.trgthingCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "trgthingCode"));

				// 에너지 생성타입 select box
				this.renewTypeCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "renewTypeCode"));

				// 원단위 구분 select box
				this.orgUnitDvsnCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "orgUnitDvsnCode"));

				// 계측 주기 select box
				this.instmnPeriodCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "instmnPeriodCode"));

				// 집계대상 select box
				this.agrgtnTargetCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "agrgtnTargetCode"));

				// 물리 관제점 유형
				this.phscPointUsageList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "phscPointUsage"));

				// 가상(논리) 관제점 유형
				this.locPointUsageList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "locPointUsage"));

				this.isIgnoreZeroList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "isIgnoreZero"));
				this.isCovList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "isCov"));
				this.setModel();
			},
			commonSmallCodeList(commonCode) {
				let targetData = [];

				commonCode.map((v) => {
					if (v.smallCodeList) {
						v.smallCodeList.map((t) => {
							targetData.push(t);
						});
					}
				});

				return targetData;
			},

			async getUnitLargeCodeList() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.unitCode.getUnitLargeCodeList();
					const data = result.data;

					// 단위 대분류 select box
					this.unitLargeCodeList = data;
					this.covUnitLargeCodeList = data;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async getUnitSmallCodeList(unitLargeCode) {
				// 단위 소분류

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				if (this.isEmpty(unitLargeCode)) return;

				try {
					const result = await backEndApi.unitCode.getUnitSmallCodeList(unitLargeCode);
					this.unitSmallCodeList = result.data;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async ptAddrDuplCheck() {
				try {
					await backEndApi.pointInfo.checkDuplPointInfo(this.model.ptAddr);
					this.forceErrorHandle = false;
				} catch (err) {
					this.forceErrorHandle = true;
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.DUPLICATE_DATA, popupMessages.POINT_DATA_DUP_POPUP_MESSAGE, this.alertNoti)
						.errHandling(true);

					return;
				}
			},
			async getCovUnitSmallCodeList(covUnitLargeCode) {
				// 변환 단위 소분류

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/

				if (this.isEmpty(covUnitLargeCode)) return;

				try {
					const result = await backEndApi.unitCode.getUnitSmallCodeList(covUnitLargeCode);
					this.covUnitSmallCodeList = result.data;
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			async getZoneListHierarchi() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.zoneInfo.getZoneListHierarchi();
					const data = result.data;

					this.zoneInfoListOption = [];

					let temp = [];
					temp.push(data);
					this.options = this.setZoneTreeOption(temp);
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				} finally {
					this.applyZoneIdx = 1;
				}
			},
			setZoneTreeOption(resultData) {
				resultData.forEach((data) => {
					this.zoneInfoListOption.push(data);

					if (data["children"]) {
						this.setZoneTreeOption(data["children"]);
					}
				});
			},

			isValidation() {
				var flag = true;

				for (let i = 0; i < this.valCheckCategory.length; i++) {
					const val = this.valCheckCategory[i];
					if (this.model[val.key] === "" || this.model[val.key] === null) {
						this.alertNoti(val.name + popupMessages.POINT_INPUT_ESSENTIAL_VALID_POPUP_MESSAGE);
						flag = false;
						break;
					}
				}

				return flag;
			},
			async onRemove() {
				console.log("Grid detail : onRemove");

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const popupResult = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
					if (!popupResult.value) return;

					await backEndApi.pointInfo.deletePointInfo(this.model.ptIdx);
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			bindNumber(event) {
				let number = event.data;

				const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

				// 한글, 영문 체크
				if (reg.exec(number) !== null) {
					event.target.value = number.replace(/[^0-9]/g, "");
				}

				// .... 만 입력하게 될 경우 체크
				if (isNaN(parseFloat(number))) {
					event.target.value = "";
				}
			},
			async onChangeUnitLargeCode() {
				if (this.model.unitLargeCode) {
					await this.getUnitSmallCodeList(this.model.unitLargeCode);
				} else {
					this.unitSmallCodeList = null;
					this.model.unitSmallCode = "";
				}
			},
			async onChangeCovUnitLargeCode() {
				if (this.model.covUnitLargeCode) {
					await this.getCovUnitSmallCodeList(this.model.covUnitLargeCode);
				} else {
					this.covUnitSmallCodeList = null;
					this.model.covUnitSmallCode = "";
				}
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>
