const popupMessages = require("@src/consts/popupMessageConsts")

const SearchTypeLimit = { Log: 1, Min: 1, Quarter: 14, Hour: 31, Day: 365 * 3, Month: 365 * 10, Year: 365 * 999 };

export default {
	dateLengthCheck(timeDsvn, fromDate, toDate) {
		let formatFromDate = "";
		let formatToDate = "";

		if (timeDsvn === "Log" || timeDsvn === "Min") {
			formatFromDate = fromDate;
			formatToDate = toDate;
		} else if (timeDsvn === "Month" || timeDsvn === 'Week') {
			formatFromDate = fromDate.substring(0, 4) + "-" + fromDate.substring(4, 6) + "-01";
			formatToDate = toDate.substring(0, 4) + "-" + toDate.substring(4, 6) + `-${new Date(Number(toDate.substring(0, 4)), Number(toDate.substring(4, 6)) + 1, 0).getDate()}`;
		} else if (timeDsvn === "Year") {
			formatFromDate = fromDate.substring(0, 4) + "-01-01";
			formatToDate = toDate.substring(0, 4) + "-12-31";
		} else {
			formatFromDate = fromDate.substring(0, 4) + "-" + fromDate.substring(4, 6) + "-" + fromDate.substring(6, 8);
			formatToDate = toDate.substring(0, 4) + "-" + toDate.substring(4, 6) + "-" + toDate.substring(6, 8);
		}

		let from = new Date(formatFromDate);
		let to = new Date(formatToDate);
		
		from.setMilliseconds(0);
		from.setSeconds(0);
		to.setMilliseconds(0);
		to.setSeconds(0);

		let diff = to.getTime() - from.getTime();
		let diffDay = Math.abs(diff) / (1000 * 60 * 60 * 24);

		console.warn(`searchCond fromDate(${formatFromDate}), toDate(${formatToDate}) : diff:(${diffDay})`);

		return { diff, diffDay, from };
	},

	wrongDateMessage(timeDsvn, diffDay) {
		console.log(timeDsvn, diffDay);
		// console.log(this.MonthDayCalc(fromDate));
		let message = "";
		switch (timeDsvn) {
			case "Log":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`원시로그 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_LOG_POPUP_MESSAGE;
				}
				break;
			case "Min":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`분단위 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_MIN_POPUP_MESSAGE;
				}
				break;
			case "Quarter":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`15분 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_QUARTER_POPUP_MESSAGE;
				}
				break;
			case "Hour":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`시간대 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_HOUR_POPUP_MESSAGE;
				}
				break;
			case "Day":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`일 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_DAY_POPUP_MESSAGE;
				}
				break;
			case "Week":
				if (diffDay > 120) {
					console.log("주간 범위 초과 (미지원)");
					message = popupMessages.ENERGY_SEARCH_WEEK_POPUP_MESSAGE;
				}
				break;
			case "Month":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`월 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_MONTH_POPUP_MESSAGE;
				}
				break;
			case "Year":
				if (diffDay > SearchTypeLimit[timeDsvn]) {
					console.log(`연도 데이터 검색한계 초과 : ${diffDay}/${SearchTypeLimit[timeDsvn]}`);
					message = popupMessages.ENERGY_SEARCH_YEAR_POPUP_MESSAGE;
				}
				break;
			default:
				message = "";
				break;
		}

		return message;
	},

	MonthDayCalc(from) {
		// console.log(from);
		// console.log(new Date(from.getFullYear(), from.getMonth()+1, 0));
		// console.log(new Date(from.getFullYear(), from.getMonth()+1, 0).getDate());
		return new Date(from.getFullYear(), from.getMonth() + 1, 0).getDate();
	},

	searchParamValCheck(param) {
		let message = "";

		if (param.fromDate > param.toDate) {
			message = popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE;
		}

		// if(param.fromDate > param.toDate){
		//   alert('선택 시간이 잘못되었습니다. 다시 한번 확인해주세요.');
		//   return true;
		// }
		return message;
	},

	previousDay(timeDsvn) {		
		return ('Log,Min,Quarter,Hour,Hourly,'.includes(timeDsvn));
	},
	previousMonth(timeDsvn) {
		return ('Log,Min,Quarter,Hour,Day,Month,Hourly,Daily,Monthly'.includes(timeDsvn));
	},
	previousYear(timeDsvn) {
		return ('Log,Min,Quarter,Hour,Day,Month,Year,Monthly,Yearly'.includes(timeDsvn));
	},
};
