<!-- 설비 유형 상세 (EquipTypeDetail) -->
<template>
    <div class="detail-container" v-if="detailInfo">
        <!-- 2.상세 정보 제목 영역 -->
        <div class="detail-header">
            <div class="col detail-title">
                <span class="title" v-if="viewTitle">{{ viewTitle }}</span>
                <span class="badge ptAddr"> {{ `${detailInfo.driverType} (V${detailInfo.version})` }} </span>
                <span class="badge ptIdx"> {{ detailInfo.propName }} </span>
                <span class="badge ptIdx"> {{ detailInfo.remark }} </span>
                <span class="badge option"> {{ `CT${detailInfo.ct}` }} </span>
                <!-- <div>
                    <span class="badge option"> {{ ptTypeCode }} </span>
                    <span class="badge option"> {{ energySourceCode }} </span>
                    <span class="badge option"> {{ unitSmallCode }} </span>
                    <span class="badge option"> {{ dataSourceType }} </span>
                </div> -->
            </div>
            <div class="detail-control">
                <span class="status-icon" :class="{ active: isEditMode }" @click="handleIconClick('mode', $event)"
                    v-b-tooltip.hover :title="$t('정보 수정')">
                    <i class="fas fa-edit" aria-hidden="true"></i>
                </span>
            </div>
        </div>


        <!-- 3.상세 정보 내용 영역 -->
        <div class="detail-content">
            <div class="form-group">
                <div class="row mb-4">
                    <div class="col-lg-4">
                        <label for="frame">
                            <trans>FRAME</trans>
                        </label>
                        <input type="text" id="frame" class="form-control" v-model="detailInfo.frame"
                            :disabled="!isEditMode" />
                    </div>
                    <div class="col-lg-4">
                        <label for="fc">
                            <trans>FC</trans>
                        </label>
                        <input type="text" id="fc" class="form-control" v-model="detailInfo.fc"
                            :disabled="!isEditMode" />
                    </div>
                    <div class="col-lg-4">
                        <label for="scale">
                            <trans>SCALE</trans>
                        </label>
                        <input type="text" id="scale" class="form-control" v-model="detailInfo.scale"
                            :disabled="!isEditMode" />
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-lg-4">
                        <label for="direction">
                            <trans>입출력</trans>
                        </label>
                        <select v-model="detailInfo.direction" class="form-control" id="direction"
                            :disabled="!isEditMode">
                            <option value="">{{ $t('선택') }}</option>
                            <option v-for="code in $store.state.commonCodes.ioType" :key="code.value"
                                :value="code.value">{{
                                    code.text }}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="valueType">
                            <trans>값유형</trans>
                        </label>
                        <select v-model="detailInfo.valueType" class="form-control" id="valueType"
                            :disabled="!isEditMode">
                            <option value="">{{ $t('선택') }}</option>
                            <option v-for="code in $store.state.commonCodes.valueTypeCode" :key="code.value"
                                :value="code.value">{{
                                    code.text }}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="dataType">
                            <trans>데이터 유형</trans>
                        </label>
                        <input type="text" id="dataType" class="form-control" v-model="detailInfo.dataType"
                            :disabled="!isEditMode" />
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-4">
                        <label for="ptTypeCode">
                            <trans>집계 유형</trans>
                        </label>
                        <select v-model="detailInfo.ptType" class="form-control" id="ptTypeCode"
                            :disabled="!isEditMode">
                            <option value="">{{ $t('선택') }}</option>
                            <option v-for="code in $store.state.commonCodes.ptTypeCode" :key="code.value"
                                :value="code.value">{{
                                    code.text }}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="unitType">
                            <trans>단위 유형</trans>
                        </label>
                        <select v-model="detailInfo.unitType" id="unitType" class="form-control"
                            :disabled="!isEditMode">
                            <option value="">선택</option>
                            <option v-for="(value) in $store.state.unitTypes" :key="value.value" :value="value.value"
                                id="unitType"> {{ value.text }} </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="unit">
                            <trans>단위</trans>
                        </label>
                        <select v-model="detailInfo.unit" id="unit" class="form-control" :disabled="!isEditMode">
                            <option value="">선택</option>
                            <option v-for="(code) in $store.state.unitCodes[detailInfo.unitType]" :key="code.value"
                                :value="code.value">
                                {{ code.text }}
                            </option>
                        </select>
                    </div>
                </div>
                
                <div class="row mb-4">
                    <div class="col-md-6">
                        <label for="min">
                            <trans>최소값</trans>
                        </label>
                        <input type="text" id="min" class="form-control" v-model="detailInfo.min"
                            :disabled="!isEditMode" />
                    </div>
                    <div class="col-md-6">
                        <label for="max">
                            <trans>최대값</trans>
                        </label>
                        <input type="text" id="max" class="form-control" v-model="detailInfo.max"
                            :disabled="!isEditMode" />
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-3">
                        <label for="encodeType">
                            <trans>인코딩 유형</trans>
                        </label>
                        <select v-model="detailInfo.encodeType" id="encodeType" class="form-control" :disabled="!isEditMode">
                            <option value="">선택</option>
                            <option v-for="(type) in $store.state.commonCodes.encodeType" :key="type.value"
                                :value="type.value">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-9">
                        <div class="row m-0">
                            <div class="col-md-3">
                                <label for="encodeDesc">
                                    <trans>인코팅 규칙</trans>
                                </label>
                            </div>
                            <div class="col-md-9 pr-0">
                                <!-- <input type="text" id="encodeDesc" class="form-control" v-model="detailInfo.encodeDesc"
                                    :disabled="!isEditMode" /> -->
                                <textarea class="form-control" id="encodeDesc" :placeholder="$t('인코팅 규칙')" v-model="detailInfo.encodeDesc"
                                    :disabled="!isEditMode" rows="3" style="resize: none;"></textarea>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-md-2 ">
                        <label for="rmk">
                            <trans>설명</trans>
                        </label>
                    </div>
                    <div class="col-md-10">
                        <textarea class="form-control" id="rmk" :placeholder="$t('관제점 설명')" v-model="detailInfo.remark"
                            :disabled="!isEditMode" rows="3" style="resize: none;"></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group">

            </div>
        </div>

        <!-- 3.하단 영역 -->
        <div class="detail-bottom ">
            <!-- 3.2 하단 제어 버튼 -->
            <div class="detail-bottom-buttons">
                <button type="button" class="btn btn-primary detail-btn" v-if="isEditMode"
                    @click="handleSave(detailInfo, isEditMode)">저장</button>
                <button type="button" class="btn btn-secondary detail-btn" v-if="detailInfo"
                    @click="handleClose($event)">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>

import backEndApi from "@src/api/backEndApi";

export default {
    components: {},
    props: ["options", "title"],
    async created() { },
    mounted() {
        console.log('DriverPropDetail.mounted() called')
        this.loadData();
    },
    destroyed() {
        console.log('DriverPropDetail.destroyed() called')
    },
    data() {
        return {
            detailInfo: null,
            mode: 'view',            // 편집모드 : edit/view
        };
    },
    computed: {
        isEditMode() {
            return this.mode === 'edit' || this.mode === 'new';
        },
        viewTitle() {
            return this.title;
        },
    },
    watch: {},
    methods: {
        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                // console.log('alertConfirmWarning() result : ', result);
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        async loadData() {
            console.log('DriverPropDetail loadData() is called.');

            let { driverType, version, direction, propertyName } = this.options;

            try {
                let result = await backEndApi.driver.getDriverProp(driverType, version)

                if (result.status == 200) {
                    this.detailInfo = result.data.find((v) => v.direction == direction && v.propertyName == propertyName);
                    this.mode = 'view';
                }

                console.log('DriverPropDetail loadData.', this.detailInfo);
            } catch (e) {
                this.alertNoti("데이터를 조회할 수 없습니다.");
                console.error(e);
            }
        },

        clearData() {
            console.log('DriverPropDetail clearData() is called.');
            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },

        async saveData() {
            console.log('DriverPropDetail saveData() is called.', this.detailInfo)

            try {
                let result = await backEndApi.driver.updateDriverProp(this.detailInfo)

                if (result.status == 200) {
                    await this.alertNoti("데이터를 저장하였습니다.");

                    this.mode = 'view';
                }
            } catch (e) {
                this.alertNoti("데이터를 저장할 수 없습니다.");
                console.error(e);
            }
        },

        async handleSave(info) {
            console.log('DriverPropDetail handleSave() is called.', info)

            await this.saveData();
        },

        async handleClose(event) {
            console.log('DriverPropDetail handleClose() is called')

            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            this.clearData();
            this.$emit('closed');

            return true;
        },

        async handleIconClick(type) {
            if (type == 'mode') {
                this.mode = this.isEditMode ? 'view' : 'edit';
            } else {
                console.error('Unknown event type. ', type);
            }
        },
    },
}

</script>

<style scoped>

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 8px 8px 8px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    overflow: hidden;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-control {
    /* margin-bottom: 8px !important; */
    display: flex;
    justify-content: flex-end;
}

.detail-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 4px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

.form-group {
    width: 100%;
    margin-bottom: 8px;
}

.row {
    width: 100%;
    margin-bottom: 8px;
}

.detail-btn {
    /* height: 50px; */
    margin: 0px 4px 0px 4px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    display: inline-block;
    margin: 2px;
    padding: 0px 10px 0px 10px;

    min-width: 20px;
    white-space: nowrap;
    /* background-color: #777; */

    color: #fff;
    line-height: 22px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;

    text-align: center;
    vertical-align: middle;
}

.badge.ptAddr {
    background-color: #FF6D00;
    color: #fff;
    min-width:60px;
}

.badge.ptIdx {
    background-color: #546E7A;
    color: #fff;
    min-width:60px;
}

.badge.option {
    background-color: #CFD8DC;
    color: #000;
    min-width:60px;
}

.status-icon {
    font-size: 16px;
    color: #546E7A;
    background-color: #EEE;
    border: solid #CCC 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #CCC;
}

.status-icon.active {
    color: #FFF;
    background-color: #546E7A;
}
</style>