<template>
    <div>
        <canvas ref="canvas" @click="handleClick"></canvas>
    </div>
</template>

<script>
import VueChartJS from 'vue-chartjs';

export default {
    extends: VueChartJS.Bar,
    mixins: [VueChartJS.mixins.reactiveProp],
    props: ['context', 'chartData', 'options'],
    data() {
        return {
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
    methods: {
        handleClick(event) {
            const chart = this.$data._chart
            const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
            console.log('handleClick() chart :', chart);
            console.log('handleClick() element :', elements);
            
            const chartArea = chart.chartArea;
            console.log('handleClick() chartArea:', chartArea);

            if (elements.length > 0) {
                const elementIndex = elements[0]._index;
                const xValue = this.chartData.labels[elementIndex];
                const yValue = this.chartData.datasets[0].data[elementIndex];

                // console.log('elements : ', elements);
                // console.log('elementIndex : ', elementIndex);
                // console.log('originalXValue : ', originalXValue);
                // console.log('yValue : ', yValue);

                this.$emit('chartClick', this.context, event, { xValue, yValue });
            }
        }
    },
};
</script>
