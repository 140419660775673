//import config from "../../config.js";
import config from "@libs/config.js";
import backEndWsc from "../api/backEndWsc.js";

const wsc = {
  client: null,
  wssUrl: config.backend.wssUrl,
  createWsc(store) {
    let that = this;
    console.log("PageSocket.js that : ", that)
    try {
      if (store == null) throw "backEndWsc store가 제공되지 않았습니다.";

      if (that.client != null) {
        // mounted 가 여러번 호출되면, wsc 인스턴스가 여러개 생성될 수 있음.
        // console.debug("WSC 이미 존재합니다.");
        return;
      }

      // console.debug("WSC를 생성합니다.");

      that.client = new backEndWsc(config.backend.wssUrl, {
        open: () => {
          // client
          // client.send( { name: "*", command: "monitor" } );
          store.commit("UPDATE_IS_WSC_CONNECTED", true);
        },
        receive: function({ data }) {
          try {
            // console.log(data);

            if (that.onreceive) that.onreceive(store, JSON.parse(data));
          } catch (err) {
            console.error("WSC receive", err);
          }
        },
        close: function() {
          store.commit("UPDATE_IS_WSC_CONNECTED", false);
        },
      });

      // console.debug("WSC 시작합니다.");
      that.client.start();
    } catch (err) {
      console.error("WSC", err);
    }
    return this;
  },
  destroyWsc() {
    let that = this;
    try {
      if (that.client != null) {
        console.debug("WSC를 제거합니다.");
        that.client.destroy();
        that.client = null;
      }
    } catch (err) {
      console.error("WSC", err);
    }
  },
  // onreceive:null,
  onreceive(store, msg) {
    try {
      // console.log(msg);
      // console.debug("WSC onreceive ", msg.command );
      // console.debug("WSC onreceive ", msg.command, JSON.stringify(msg) );

      // mutations 실행
      //store.commit(msg.command, msg);

      // action 실행
      // store.dispatch("UPDATE_MENUS");

      if (msg.command === "UPDATE_PT_LIST") store.commit(msg.command, msg);
      else if (msg.command === "UPDATE_TOTAL_REAL") store.commit(msg.command, msg);
      else if (msg.command === "UPDATE_LIVE_REAL") store.commit(msg.command, msg);
      else if (msg.command === "UPDATE_ALARM_LOGS") store.commit(msg.command, msg);
      else if (msg.command === "UPDATE_EQUIP_CTRL") store.commit(msg.command, msg);
    } catch (err) {
      console.error("WSC", err);
    }
  },
};

export default wsc;
