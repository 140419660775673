import resource from "./Resource.js";
import PageOptions from "../config/PageOptions.vue";
import Enums from "@src/enums.js";
import ruleFailedMessagePopup from "@src/consts/ruleFailedMessagePopup.js";

const GlobalPlugin = {
	install(Vue, options) {
		this.options = options;

		Vue.GROUP_LEVELS = {
			Super: 1,
			Engineer: 2,
			Admin: 3,
			User: 4,
			Partner: 5,
		};

		// 2. 전역 에셋 추가
		// 전역 사용자 정의 디렉티브 v-focus 등록
		Vue.directive("focus", {
			// 바인딩 된 엘리먼트가 DOM에 삽입되었을 때...
			inserted: function(el) {
				// 엘리먼트에 포커스를 줍니다
				el.focus();
			},
		});

		// 권한 체크 디렉디브
		// 사용법 : <div v-xe-pm.E.A ></div>
		Vue.directive("xe-pm", {
			inserted(el, binding, vnode) {
				let hasPermission = vnode.context.checkRole(binding.modifiers);
				// console.log(hasPermission);
				if (hasPermission === false) {
					// 권한이 없으면 해당노드 삭제..
					vnode.elm.parentElement.removeChild(vnode.elm);
				}
			},
		});

		// 다국어 디렉티브
		// <label v-trans="'Point Address'" />
		Vue.directive("trans", {
			inserted(el, binding) {
				let p = document.createElement(`trans`);
				p.innerText = binding.value;

				el.appendChild(p);
			},
		});

		const CODE = {
			Drag: {
				Point: "Point",
				PointGroup: "PointGroup",
			},
		};

		// 화면에 표시할 문자열 표현 - 이 함수는 화면에서 데이터를 표시할 목적으로만 사용해야 함.
		function _disp(v, digits, comma, defaultV = null) {
			// 값이 null일 수 있음..
			if (v == null || v == undefined) v = defaultV;
			if (comma) return global.xe.comma(v, digits); // 콤마는 소수점처리를 동시에 할 수 있음...
			return digits > -1 && v && typeof v.toFixed == "function" ? v.toFixed(digits) : v;
		}

		let GRID_MAX = 10000;
		// // 3. 컴포넌트 옵션 주입
		Vue.mixin({
			data: function() {
				return {
					pageOptions: PageOptions,
					CODE: CODE,
					GRID_MAX_HEIGHT: "700px",

					// rowStyleClassFn 정리해야 함.
					GRID_OPT: {
						lineNumbers: false,
						searchOptions: { enabled: false, placeholder: "Search this table" },
						paginationOptions: {
							enabled: true,
							position: "bottom",
							perPage: parseInt(global.xe.is(localStorage.gridPerPage, 20)),
							perPageDropdown: [20, 100, 500, GRID_MAX],
							mode: "pages",
							dropdownAllowAll: false,

							nextLabel: "next", // TODO: 다국어
							prevLabel: "prev",
							rowsPerPageLabel: "Rows",
							ofLabel: "of",
							pageLabel: "page", // for 'pages' mode
							allLabel: "All",
						},
						sortOptions: { enabled: true },
						selectOptions: {
							enabled: false,
							selectOnCheckboxOnly: true,
							selectionInfoClass: "alert alert-info m-b-0 no-rounded-corner",
							selectionText: "rows selected",
							clearSelectionText: "clear",
						},
						maxHeight: "670px",
						fixedHeader: true,
						styleClass: "vgt-table striped condensed",
						rowStyleClass: () => {
							return "xe-grid-row xxxx";
						},
					},
					ERR_KIND: Enums.ERR_KIND,
					DOCS: [],
					MainWindowRect: { width: 0, height: 0 },
					AlertPopupError: ruleFailedMessagePopup,
				};
			},
			computed: {
				// pageOptions(){
				//   return PageOptions;
				// },
				$inverseMode() {
					return PageOptions.pageContentInverseMode;
				},
				panelVariant() {
					return this.$theme === "facebook" ? "default" : "inverse";
				},
				$theme() {
					return localStorage.theme || "facebook";
				},
				$labelTheme() {
					return localStorage.labelTheme || "blue";
				},
				$headerInverse() {
					return localStorage.headerInverse || false;
				},
				loginLevels() {
					// 로그인 사용자가 속한 그룹 목록
					let loginGroups = this.$store.getters.loginGroups;

					if (!Array.isArray(loginGroups)) return [];

					// 로그인 사용자가 속한 그룹 목록들이 레벨값 형식으로 변환해주기..
					return loginGroups
						.map((group) => {
							return Vue.GROUP_LEVELS[group.builtIn];
						})
						.filter((v) => v);
				},
				isMobile() {
					return this.checkMobile();
				},
				liveDate() {
					return this.$store.getters.liveDate;
				},
				// Dark - Content Inverse Mode
				normalTextStyle() {
					// 다크모드일때  배경이 밝으면 글자를 원래 어두운색으로
					return [{ "text-inverse": this.$inverseMode, "text-white": !this.$inverseMode }];
				},
				normalBtnStyle() {
					// 다크모드일때  배경이 밝으면 글자를 원래 어두운색으로
					return [{ "text-inverse btn-grey": this.$inverseMode, "text-white btn-inverse": !this.$inverseMode }];
				},
				normalBg() {
					// 다크모드일때  배경이 밝으면 글자를 원래 어두운색으로
					return [{ "bg-black": !this.$inverseMode, "bg-white": this.$inverseMode }];
				},
				inverseTextColor() {
					// 다크모드일때  배경 검정이므로 글자 흰색
					return this.$inverseMode ? "rgba(255,255,255, .95)" : "rgba(0,0,0, .85)";
				},
				inverseTextClass() {
					// 다크모드일때  배경 검정이므로 글자 흰색
					return [{ "text-white": this.$inverseMode, "text-inverse": !this.$inverseMode }];
				},
				inverseBg() {
					// this.$theme == 'transparent' ? '' :  '';
					return [{ "bg-black": this.$inverseMode, "bg-white": !this.$inverseMode }];
				},
			},
			watch: {},
			filters: {
				codeChangelengthText(val, len = 50, defaultStr = "") {
					if (global.xe.isEmpty(val)) return defaultStr; // this.$t('비었음')
					if (val.length > len) return val.substring(0, len) + "...";
					return val;
				},
				codeAlarmTypes(val) {
					switch (val) {
						case "A0":
							return "Low / High";
						case "A1":
							return "Low low / High high";
						case "D0":
							return "True / False";
						default:
							return "";
					}
				},
				codeIsRun(val) {
					return val ? "동작중".$t() : "중지됨".$t();
				},
				codeIsUse(val) {
					return val ? "사용중".$t() : "사용중지".$t();
				},
				codeIsYn(val) {
					return val ? "Y".$t() : "N".$t();
				},
				codeIsVirtual(val) {
					return val ? "Virtual".$t() : "Normal".$t();
				},
				codePtType(val) {
					switch (val) {
						case "LST":
							return "Last";
						case "SUM":
							return "Sum";
						case "MAX":
							return "Max";
						case "AVG":
							return "Avg";
						default:
							return "";
					}
				},
				codeValueType(val) {
					switch (val) {
						case "analog":
							return "아날로그".$t();
						case "digital":
							return "디지털".$t();
						default:
							return "";
					}
				},
				codeDirection(val) {
					switch (val) {
						case "In":
							return "입력".$t();
						case "Out":
							return "출력".$t();
						case "Both":
							return "양방향".$t();
						default:
							return "";
					}
				},
				codeEnergyType(val) {
					switch (val) {
						case "elec":
							return "전기".$t();
						case "gas":
							return "가스".$t();
						case "heat":
							return "난방".$t();
						case "water":
							return "수도".$t();
						default:
							return "";
					}
				},
				codeTimeMode(val) {
					switch (val) {
						case "Month":
							return "월별".$t();
						case "Week":
							return "주별".$t();
						default:
							return "";
					}
				},
				codeTimeType(val) {
					return global.xe.PtLib.codeTimeType(val);
				},
				codeTimeTable(val) {
					return global.xe.PtLib.codeTimeTable(val);
				},
				filterDisp(val, digits, comma, defaultV = null) {
					return _disp(val, digits, comma, defaultV);
				},
				filterComma(val, fixed) {
					return global.xe.comma(val, fixed);
				},
				filterFormat(val, pattern = "yyyy-MM-dd HH:mm:ss") {
					return global.xe.isEmpty(val) ? "" : val.format(pattern || "yyyy-MM-dd HH:mm:ss");
				},
				// 2022.04.26 박상문
				// 신규 추가
				// 소수점 무시, 3자리마다 콤마 찍기
				numberWithCommas: function(value) {
					var num = new Number(value);

					if (isNaN(num)) {
						return "-";
					} else {
						let str = num.toString().split(".");
						str[0] = str[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						if (!str[1]) {
							let fmStr = str[0];
							return fmStr;
						} else {
							let fmStr = str[0] + "." + str[1];
							return fmStr;
						}

						//return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
				},
			},
			created() {
				window.addEventListener("resize", this.onMainWindowResized);
			},
			beforeDestroy() {
				window.removeEventListener("resize", this.onMainWindowResized);
			},
			mounted() {
				if (global.vm) this.__Docs(global.vm);
				this.onMainWindowResized();

				this.MainWindowRect.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
				this.MainWindowRect.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			},
			methods: {
				async onValidate(v, successFunction, FP) {
					// let FP = JSON.parse(JSON.stringify(rowData));
					let result = await v.validateWithInfo();

					// for (let i = 0; i < FP.length; i++) {
					// 	if (this.isEmpty(FP[i].checked)) {
					// 		if (FP[i].checked === false) {
					// 			let errorsResultObject = Object.keys(result.errors);
					// 			let fieldsResultObject = Object.keys(result.fields);

					// 			errorsResultObject.forEach((v) => {
					// 				if (v.includes(i.toString())) {
					// 					delete result.errors[v];
					// 				}
					// 			});

					// 			fieldsResultObject.forEach((v) => {
					// 				if (v.includes(i.toString())) {
					// 					delete result.fields[v];
					// 				}
					// 			});

					// 			console.log(result);

					// 			FP.splice(i, 1);
					// 			i--;
					// 		}
					// 	}
					// }

					// console.log(FP);
					try {
						// console.log(result);

						if (result.isValid === false) {
							// console.log("InValid");
							await this.alertPopupError(result);
						} else {
							// console.log("valid");

							successFunction(FP);
						}
					} catch (e) {
						console.error(e);
					}
				},
				async alertPopupError(v) {
					let errors = v.errors;
					let data = v.fields;
					// console.log(errors, data);
					let idName = Object.keys(errors).find((v) => errors[v][0] !== "");
					// console.log(idName);

					let failedRules = data[idName].failedRules;
					let fieldName = data[idName].name;

					let failedRulesKey = Object.keys(failedRules)[0];
					// console.log(idName, errors, data, failedRulesKey);

					let message = ruleFailedMessagePopup.failedPopupMessage(failedRulesKey, fieldName);
					// console.log(message);
					await this.alertWarning(this.$t(message));
				},
				onMainWindowResized() {
					//console.log("document.body.getBoundingClientRect()", Object.keys(document.body.getBoundingClientRect()));
					let { x, y, width, height, top, right, bottom, left } = document.body.getBoundingClientRect();

					this.MainWindowRect = { x, y, width, height, top, right, bottom, left };
				},
				setGridPerPage(params) {
					//   (params, list)
					// 그리드의 perPage를 기억하고 있다가, 화면에 다시 들어오면 복원시켜준다.
					localStorage.gridPerPage = params.currentPerPage;
				},
				blink(ui, callback) {
					// https://michaelnthiessen.com/force-re-render
					// componentKey를 ++ 하여 dom을 갱신하게 만든다.
					// <template>
					//   <component-to-re-render :key="componentKey" />
					// </template>
					// methods: {
					//   forceRerender() {
					//     this.componentKey += 1;
					//   }
					// }
					if (this.isEmpty(ui)) {
						console.warn("isShow를 선언하지 않고 사용하였습니다.");
						return;
					}

					const that = this;
					ui.isShow = false;
					Vue.nextTick(() => {
						ui.isShow = true;

						Vue.nextTick(() => {
							if (callback) callback(that);
						});
					});
				},
				checkMobile() {
					return global.xe.checkMobile();
				},
				percentage(val, total, fixed = 0) {
					if (global.xe.isEmpty(val)) return -1;
					return val.percentage(total, fixed);
				},
				storageSize(size, unit = "GB", fixed = 1, si = false) {
					// 메모리와 HDD는 Size 기준이 다름
					// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
					if (global.xe.isEmpty(size)) return "0" + unit;

					let units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]; // si가 false 일때 MiB 단위가 다름
					let lvl = units.findIndex((v) => v == unit);

					let val = size;
					let thresh = si ? 1000.0 : 1024.0;
					while (val > thresh) {
						val = val / thresh;
						++lvl;
					}

					return val.toFixed(fixed) + units[lvl];
				},
				timespan(ms, unit = "Sec", fixed = 0) {
					if (isNaN(ms)) {
						return `0 ${unit}`;
					} else if (global.xe.isEmpty(ms)) {
						return `0 ${unit}`;
					} else {
						let tt = new Date(0, 1, 0);

						switch (unit) {
							case "ms":
								tt = tt.addMs(ms);
								break;
							case "Sec":
								tt = tt.addSec(ms);
								break;
							case "Min":
								tt = tt.addMin(ms);
								break;
							case "Hour":
								tt = tt.addHour(ms);
								break;
							case "Day":
								tt = tt.addDay(ms);
								break;
							case "Month":
								tt = tt.addMonth(ms);
								break;
							case "Year":
								tt = tt.addYear(ms);
								break;
						}

						// 미작동으로 아래 코드 작성
						// let { val, type } = new Date(0,1,0).passedSpan(tt);

						// 신규 passedSpan 코드 추가
						// let flagTime = new Date();
						let result = null;

						// if(global.xe.isEmpty(flagTime)) return "";
						if (!tt) tt = new Date();

						let sec = Math.floor((tt.getTime() - new Date().getTime()) / 1000);

						if (sec < 1) {
							result = { val: sec, type: "ms" };
						} else if (sec < 60) {
							result = { val: sec, type: "Sec" };
						} else if (sec < 60 * 60) {
							result = { val: Math.floor(sec / 60), type: "Min" };
						} else if (sec < 60 * 60 * 24) {
							result = { val: Math.floor(sec / 60 / 60), type: "Hour" };
						} else if (sec < 60 * 60 * 24 * 30) {
							result = { val: Math.floor(sec / 60 / 60 / 24), type: "Day" };
						} else if (sec < 60 * 60 * 24 * 365) {
							result = { val: Math.floor(sec / 60 / 60 / 24 / 30), type: "Month" };
						} else {
							let y = Math.floor(sec / 60 / 60 / 24 / 365);
							if (y > 1000) return ""; // 129년이 나옴..
							result = { val: y, type: "Year" };
						}

						return result.val.toFixed(fixed) + " " + result.type;
					}
				},
				codeTimeType(val) {
					return global.xe.PtLib.codeTimeType(val);
				},
				// clone(obj) {
				// 	return JSON.parse(JSON.stringify(obj));
				// },
				merge(target, ...sources) {
					return global.xe.merge(target, ...sources);
				},
				isNaU(v) {
					return global.xe.isNaU(v);
				},
				isEmpty(v) {
					return global.xe.isEmpty(v);
				},
				isEmptyObj(v) {
					return global.xe.isEmptyObj(v);
				},
				parseBool(v) {
					return global.xe.parseBool(v);
				},
				isNumeric(v, opt) {
					return global.xe.isNumeric(v, opt);
				},
				isNegative0(v) {
					return global.xe.isNegative0(v);
				},
				comma(num, fixed) {
					return global.xe.comma(num, fixed);
				},
				forObj(obj, callback) {
					return global.xe.forObj(obj, callback);
				},
				checkSpecialChar(event) {
					// eslint-disable-next-line
					let special = /[~!@\#$%^&*\()\-=+_']/gi;

					// TODO: event가 없는데 어떻게 ???? 작동이 되지?
					if (special.test(event.key)) event.returnValue = false;
				},
				// 컬러셋 안에서의 랜덤 색상
				getRandomColor() {
					return resource.colorSet[Math.round(Math.random() * resource.colorSet.length)];
				},
				getRandomId(pre) {
					return (
						(pre || "") +
						Math.random()
							.toString()
							.replaceAll(".", "_")
					);
				},
				getRandomIds(pre, size) {
					if (this.isEmpty(size)) size = 1;
					if (typeof size === "string") size = parseInt(size);

					let ids = [];
					for (let idx = 0; idx < size; idx++) {
						ids.push(this.getRandomId(pre));
					}
					return ids;
				},
				// alert : type = warn, error, success
				notiRight: function(title, text, type, data) {
					this.$notify({ group: "noti-right", title: this.$t(title), text: this.$t(text), type, data });
				},
				notiBottom: function(title, text, type, data) {
					this.$notify({ group: "noti-bottom", title: this.$t(title), text: this.$t(text), clean: true, type, data });
				},
				notiFoo: function(title, text, type, data) {
					this.$notify({ group: "foo", title: this.$t(title), text: this.$t(text), clean: true, type, data });
				},
				alertNoti: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "info",
						//showCancelButton: true,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						//cancelButtonText: this.$t("취소"),
						confirmButtonClass: "btn m-r-5 btn-primary",
						//cancelButtonClass: "btn btn-default"
						keydownListenerCapture: true,
					});
				},
				alertQuestion: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "question",
						allowEscapeKey: true,
						showCancelButton: true,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						cancelButtonText: this.$t("취소"),
						confirmButtonClass: "btn m-r-5 btn-danger",
						cancelButtonClass: "btn btn-default",
						keydownListenerCapture: true,
					});
				},
				alertConfirmWarning: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "warning",
						showCancelButton: true,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						cancelButtonText: this.$t("취소"),
						confirmButtonClass: "btn m-r-5 btn-warning",
						cancelButtonClass: "btn btn-default",
						keydownListenerCapture: true,
					});
				},
				alertWarning: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "warning",
						showCancelButton: false,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						confirmButtonClass: "btn m-r-5 btn-warning",
						cancelButtonClass: "btn btn-default",
						keydownListenerCapture: true,
					});
				},
				alertConfirmDanger: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "error",
						showCancelButton: true,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						cancelButtonText: this.$t("취소"),
						confirmButtonClass: "btn m-r-5 btn-danger",
						cancelButtonClass: "btn btn-default",
						keydownListenerCapture: true,
					});
				},
				alertDanger: function(text, title = "", more = "") {
					return this.$swal({
						title: this.$t(title),
						//text: this.$t(text) + (more? " " + more: ""),
						html: this.$t(text) + (more ? "<br />" + more : ""),
						type: "error",
						showCancelButton: false,
						buttonsStyling: false,
						confirmButtonText: this.$t("확인"),
						confirmButtonClass: "btn m-r-5 btn-danger",
						cancelButtonClass: "btn btn-default",
						keydownListenerCapture: true,
					});
				},
				//
				checkRole(allow) {
					let hasPermission = false;

					this.loginLevels.forEach((lvl) => {
						if (hasPermission == false) {
							if (lvl == Vue.GROUP_LEVELS["Super"]) hasPermission = true;
							else if (lvl == Vue.GROUP_LEVELS["Admin"]) hasPermission = true;
							else if (lvl == Vue.GROUP_LEVELS["Engineer"]) hasPermission = allow.E || false;
							else if (lvl == Vue.GROUP_LEVELS["User"]) hasPermission = allow.U || false;
							else if (lvl == Vue.GROUP_LEVELS["Partner"]) hasPermission = allow.P || false;
						}
					});

					return hasPermission;
				},
				routerPush(path) {
					if (this.$router.currentRoute.path !== path) this.$router.push(path);
				},
				clearSelection() {
					let sel;
					if ((sel = document.selection) && sel.empty) {
						sel.empty();
					} else {
						if (window.getSelection) {
							window.getSelection().removeAllRanges();
						}
						let activeEl = document.activeElement;
						if (activeEl) {
							let tagName = activeEl.nodeName.toLowerCase();
							if (tagName == "textarea" || (tagName == "input" && activeEl.type == "text")) {
								// Collapse the selection to the end
								activeEl.selectionStart = activeEl.selectionEnd;
							}
						}
					}
				},
				readStorage(propName, defaultVal = {}) {
					return global.xe.readStorage(propName, defaultVal);
				},
			},
		});

		// 4. 인스턴스 메소드 추가
		Vue.prototype.$err = function(res, callback) {
			try {
				// console.log("GlobalPlugin $err res", res, " isEmpty=", this.isEmpty(res) );

				if (res === 0) {
					// insert : object, update, delete : affrectedRows
					return false; // 정상
				}

				if (this.isEmpty(res) && !Array.isArray(res)) {
					// console.warn("반환값이 존재하지 않습니다.", res); // null이 반환된 경우..
					return true;
				}

				if (res.error) {
					// callback이 없으면 내부에서 alertNoti함..

					// 오류가 정의된 경우, res.error 는 오류코드임
					// 이외에 res.errno, res.message 가 있음..

					let errorHandle = resource.errors[res.error];
					if (!this.isEmpty(errorHandle)) {
						console.error(res.error, errorHandle);

						if (this.isEmpty(callback)) this.notiBottom(errorHandle.message);
						if (!this.isEmpty(callback)) callback(errorHandle, res.error);

						return true;
					} else if (res.error == "biz") {
						let message = this.$t(res.message);
						console.warn("[XEMS BIZ]", res.errno, message);

						if (this.isEmpty(callback)) this.notiBottom(message, `ERROR CODE: ${res.errno}`);
						if (!this.isEmpty(callback)) callback(errorHandle, message);
						return true;
					} else {
						console.warn("[XEMS ERR]", res.error, res);

						// if (this.isEmpty(callback)) this.notiBottom("오류가 발생하였습니다.", "관리자에게 문의해 주세요.");
						if (this.isEmpty(callback)) this.notiFoo("오류가 발생하였습니다.", "관리자에게 문의해 주세요.");
						if (!this.isEmpty(callback)) callback(errorHandle, res.error);
						return true;
					}
				}
			} catch (exception) {
				console.error("$ERR HANDLER >>> ", exception);
				return true;
			}

			return false;
		};

		// 다국어
		//Vue.prototype.$t = global.xe.$t;
		Vue.prototype.$t = function(val, ...fields) {
			// console.log(this);
			let resource = `${global.xe.is(this.$options.name, "")} / ${this.$vnode.tag}`;
			let remark = this.$router.currentRoute.path;

			let result = global.xe.$t(val, { remark, resource }, fields);

			return result;
		};

		// 메시지 다국어
		global.xe.$mt = function(val, data = "", option = "default") {
			// let resource = `${global.xe.is(this.$options.name, "")} / ${this.$vnode.tag}`;
			// let remark = this.$router.currentRoute.path;
			let result = global.xe.$t(val);

      if (this.locale !== "kr" || this.locale !== "ko") {
				switch (option) {
					case "length": {
						let split = result.split("characters");

						result = `${split[0]} ${this.$t(data)} characters ${split[1]}`;
						break;
					}
					default: {
						result = `${result} ${this.$t(data)}`;
						break;
					}
				}
			} else {
				result = `${this.$t(data)}${result}`;
			}

			return result;
		};

		(Vue.prototype.__Docs = function(vm) {
			//console.log("this.$options", this.$options);
			//console.log("this.components", this.$options.components);
			this.DOCS.clear();
			Object.keys(vm.components).map((key) => {
				let comp = vm.components[key];
				if (comp.__docs) {
					// console.log("components ", comp.__file, comp.__docs.trim(), comp);
					this.DOCS.append({ file: comp.__file, contents: comp.__docs.trim() });
				}
			});
		}),
			// 1. 전역 메소드 또는 속성 추가

			(Vue.convertMenuToNav = (pNav, node, menus) => {
				let nav = { parent: pNav };
				if (node.icon) nav.icon = node.icon;
				if (node.path) nav.path = node.path;
				if (node.title) nav.title = node.title;
				if (node.label) nav.label = node.label;
				if (node.badge) nav.label = node.badge;

				if (node.menuIdx) nav.menuIdx = node.menuIdx; // menu로 nav 개체를 찾기위해서 idx를 이용한다.

				let children = menus.filter((v) => v.parentMenuIdx == node.menuIdx);
				if (children.length > 0) {
					nav.children = children.map((sub) => {
						return Vue.convertMenuToNav(nav, sub, menus);
					});

					// 모든 하위노드에 children도 없는데 path도 없는 경우임.. 이런 노드의 children은 삭제해 주어야 함.. 하위메뉴에 권한이 없는 경우에 이런 현상이 나옴..
					let sub = nav.children.filter((v) => global.xe.isEmpty(v.path) && (global.xe.isEmpty(v.children) || v.children.length == 0));
					if (sub.length == nav.children.length) {
						delete nav.children;
					}

					// 자식이 있으면, path 프로퍼티가 있으면 안됨.. Nav에서 오동작함..
					delete nav.path;
				}
				return nav;
			});

		Vue.convertNavList = (menus) => {
			if (Array.isArray(menus)) {
				return menus
					.filter((v) => v.parentMenuIdx == null) // 최상위 부모메뉴를 찾는다.
					.map((menu) => Vue.convertMenuToNav(null, menu, menus)); // 모든 메뉴를 nav 형식으로 변환한다.
			}
			return [];
		};

		//#region Etc

		// Vue.js Functional Base Components Powered by CSS Modules
		// Prop validation
		// https://markus.oberlehner.net/blog/vue-functional-base-components-powered-by-css-modules/

		// this.unPersonalizable = matchingDescendants(this, /a.checkimprintfiinformation$/, {first: true});
		//
		// function matchingDescendants(vm, matcher, options) {
		//     let descendants = vm.$children;
		//     let descendant;
		//     let returnFirst = (options || {}).first;
		//     let matches = [];

		//     for (let x=0; x < descendants.length; x++) {
		//         descendant = descendants[x];

		//         //console.log("", descendant.$vnode.tag);
		//         console.log("", descendant.$vnode);

		//         // global.xe.recursive(descendant.$vnode, (key, item, type) => {

		//         //   if(key.toLowerCase().indexOf('docs') > -1){
		//         //     console.log("recursive = ", key, type, item);
		//         //   }
		//         // }, 3);
		//         // if(vm) { console.log("end"); return; }

		//         if (matcher.test(descendant.$vnode.tag)) {
		//             if (returnFirst) {
		//                 return descendant;
		//             }
		//             else {
		//                 matches.push(descendant);
		//             }
		//         }

		//         for (let y=0, len = descendant.$children.length; y<len; y++) {
		//             descendants.push(descendant.$children[y]);
		//         }
		//     }

		//     return matches.length === 0 ? false : matches;
		// }

		// <div v-keyword-highlight>very complicated template</div>
		// https://stackoverflow.com/questions/50594818/vue-custom-directive-uses-the-updated-dom-or-el/50596142#50596142
		(function() {
			let walk = null;
			function deepClone(vnodes, createElement) {
				let clonedProperties = ["text", "isComment", "componentOptions", "elm", "context", "ns", "isStatic", "key"];
				function cloneVNode(vnode) {
					let clonedChildren = vnode.children && vnode.children.map(cloneVNode);
					let cloned = createElement(vnode.tag, vnode.data, clonedChildren);
					clonedProperties.forEach(function(item) {
						cloned[item] = vnode[item];
					});
					return cloned;
				}
				return vnodes.map(cloneVNode);
			}

			function addStylesForKeywords(el, keyword) {
				if (!keyword) return;
				let n = null;
				let founds = [];
				walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
				n = walk.nextNode();
				while (n) {
					if (n.textContent.trim().length < 1) continue;
					founds.push(n);
					n = walk.nextNode();
				}
				//let result = []
				founds.forEach((item) => {
					if (new RegExp("cx", "ig").test(item.textContent)) {
						let kNode = document.createElement("span");
						kNode.innerHTML = item.textContent.replace(new RegExp("(.*?)(cx)(.*?)", "ig"), "$1<strong>$2</strong>$3");
						item.parentNode.insertBefore(kNode, item);
						item.parentNode.removeChild(item);
					}
				});
			}

			let myDirective = {};
			myDirective.install = function install(Vue) {
				let timeoutIDs = {};
				let temp = Vue.extend({
					template: "<p>{{firstName}} {{lastName}} aka {{alias}}</p>",
				});
				//let fakeVue = new temp()
				new temp();
				Vue.directive("keyword-highlight", {
					bind: function bind(el, binding) {
						// , vnode
						clearTimeout(timeoutIDs[binding.value.id]);
						if (!binding.value) return;
						timeoutIDs[binding.value.id] = setTimeout(() => {
							addStylesForKeywords(el, binding.value.keyword);
						}, 500);
					},
					componentUpdated: function componentUpdated(el, binding, vnode) {
						let fakeELement = document.createElement("div");
						//vnode is readonly, but method=__patch__(orgNode, newNode) will load new dom into the second parameter=newNode.$el, so uses the cloned one instead
						let clonedNewNode = deepClone([vnode], vnode.context.$createElement)[0];
						let test = clonedNewNode.context.__patch__(fakeELement, clonedNewNode);

						while (el.firstChild) {
							el.removeChild(el.firstChild);
						}
						test.childNodes.forEach((item) => {
							el.appendChild(item);
						});
						clearTimeout(timeoutIDs[binding.value.id]);
						timeoutIDs[binding.value.id] = setTimeout(() => {
							addStylesForKeywords(el, binding.value.keyword);
						}, 500);
					},
				});
			};
			Vue.use(myDirective);
			Vue.config.productionTip = false;
		})();

		//#endregion
	},
};

export default GlobalPlugin;
