// HEX 색상을 RGB 색상으로 변환
export function hexToRgb(hex) {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
}

// RGB 색상을 HEX 색상으로 변환
export function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

// 두 색상 간의 선형 보간
export function interpolateColor(color1, color2, factor) {
    if (typeof factor === 'undefined') factor = 0.5;
    let result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
    }
    return result;
}

// 두 색상 사이의 색상 그라데이션 생성
export function generateColorGradient(startColor, endColor, steps) {
    let startRGB = hexToRgb(startColor);
    let endRGB = hexToRgb(endColor);
    let gradient = [];

    if (!steps) return [startColor];

    for (let i = 0; i <= steps; i++) {
        let factor = i / steps;
        let rgb = interpolateColor(startRGB, endRGB, factor);
        gradient.push(rgbToHex(rgb[0], rgb[1], rgb[2]));
    }
    return gradient;
}

// import { generateColorGradient } from './colorGradient.js';

// let startColor = "#FF0000"; // 빨간색
// let endColor = "#0000FF"; // 파란색
// let steps = 10; // 10단계로 색상을 나눕니다.

// let gradient = generateColorGradient(startColor, endColor, steps);
// console.log(gradient);
